import { gql } from "@apollo/client";

const CHANGE_CAMPAIGN_STATUS = gql`
  mutation adminUpdateCampaignStatus(
    $campaignId: ID!
    $campaignStatus: String!
  ) {
    adminUpdateCampaignStatus(
      campaignId: $campaignId
      campaignStatus: $campaignStatus
    )
  }
`;

export default CHANGE_CAMPAIGN_STATUS;
