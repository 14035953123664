import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  Box,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import Chart from "react-apexcharts";
import useWindowDimensions from "../components/customHooks/windowDimensions";
import MultiSelectDropdown from "../components/SharedComponents/multiSelectDropdown";
import GET_INFLUENCERS from "../graphql/queries/getInfluencers";
import GET_CAMPAIGN_RESULTS from "../graphql/queries/getCampaignResults";
import "../style.css";
import ChartWrapper from "../components/SharedComponents/chartWrapper";
import InfluencerPerformanceChart from "../components/influencerPerformanceChart";
import CampaignCostBreakdown from "../components/campaignCostBreakdown";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
interface campaign {
  id: String;
  name: String;
  user: any;
  campaignType: String;
  category: any;
  followerPreference: [String];
  media: String;
  status: String;
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const graphToShow = [
  ["Instagram Reach", "Tiktok Reach", "Top Reach"],
  ["Instagram Engagement", "Tiktok Engagement", "Top Engagement"],
  ["Instagram Like", "Tiktok Likes", "Top Likes"],
  ["Instagram Clicks", "Tiktok Video Views", "Top Clicks"],
  ["Instagram Impressions", "Tiktok Impressions", "Top Impressions"],
  ["Instagram Followers", "Tiktok Followers", "Top Followers"],
];

type influencerType = {
  id: string;
  name: string;
  image: string;
  email: string;
  country: string;
  city: string;
  accountStatus: string;
};

type CampaignResult = {
  id: string;
  userId: number;
  campaignId: number;
  instaLikes: number;
  tiktokLikes: number;
  instaReach: number;
  tiktokViews: number;
  totalTitktokViews: number;
  instaImpressions: number;
  instaComments: number;
  tiktokComments: number;
  instaLinkClicks: number;
  instaFollowers: number;
  tiktokFollowers: number;
  // ... other fields
};

type influencerResult = {
  [userId: string]: {
    totalInstaLikes: number;
    totalTiktokLikes: number;
    totalInstaReach: number;
    totalTiktokViews: number;
    totalInstaImpressions: number;
    totalInstaComments: number;
    totalTiktokComments: number;
    totalInstaLinkClicks: number;
    campaignResultCount: number;
    totalInstaFollowers: number;
    totalTiktokFollowers: number;
    campaigns: number[];
    avgLikes: number[];
    avgReach: number[];
    avgEngagement: number[];
    clicks: number[];
    avgImpression: number[];
    avgFollowers: number[];
    tikAvglike: number[];
    tikAvgReach: number[];
    tikAvgEngagement: number[];
    tikAvgClick: number[];
    tikAvgImpression: number[];
    tikAvgFollowers: number[];
  };
};

type Results = {
  getCampaignResults: CampaignResult[];
};

const colors = [
  ["#008ffb", "#00e396"],
  ["#48dbfb", "#f368e0"],
  ["#32ff7e", "#fff200"],
  ["#48dbfb", "#f368e0"],
];

type seriesItem = {
  name: string;
  data: {};
};

interface TooltipOptions {
  enabled: boolean;
  x: {
    show: boolean;
  };
  shared: boolean;
  theme: string;
  fillSeriesColor: boolean;
  custom: ({
    series,
    seriesIndex,
    dataPointIndex,
    w,
  }: {
    series: any[]; // use proper type
    seriesIndex: number;
    dataPointIndex: number;
    w: any; // use proper type
  }) => string; // or another return type
}

interface ExtendedOptions {
  xaxis: {
    categories: string[]; // assuming names is an array of strings
  };
  fill: {
    type: string;
    colors: string[];
  };
  stroke: {
    colors: string[];
  };
  tooltip: TooltipOptions;
}

const shortNum = (value: number, decimal: number): string => {
  decimal = Math.pow(10, decimal);

  const short: string[] = ["k", "m", "b"];

  for (let i = short.length - 1; i >= 0; i--) {
    const size = Math.pow(10, (i + 1) * 3);

    if (size <= value) {
      value = ((value * decimal) / size / decimal).toFixed(1) as any; // Casting required because toFixed returns string

      if (value === 1000 && i < short.length - 1) {
        value = 1;
        i++;
      }

      return value + short[i];
    }
  }

  return value?.toString();
};

const MetricsGraphs = () => {
  const { height, width } = useWindowDimensions();
  const [selectedGraph, setSelectedGraph] = useState<number>(0);
  const [influencers, setInfluencers] = useState<[influencerType] | []>([]);
  const [selectedInfluencerIds, setSelectedInfluencerIds] = useState<any[]>([]);
  const [campaignResults, setCampaignResults] = useState([]);
  const [influencersData, setInfluencersData] = useState({});
  const [graphData, setGraphData] = useState<Array<Array<{}>>>([]);
  const [names, setNames] = useState<string[]>([]);
  const [value, setValue] = useState(0);
  let series: seriesItem[] = [];

  const options = {
    chart: {
      foreColor: "#181a19",
    },
    legend: {
      position: "bottom",
    },
    tooltip: {
      theme: "dark",
    },
    yaxis: {
      labels: {
        formatter: function (value: number) {
          return shortNum(parseFloat(value.toFixed(2)), 0);
        },
      },
    },
  };

  const graphType = [
    { id: 0, name: "Top Reach" },
    { id: 1, name: "Top Engagement" },
    { id: 2, name: "Top Likes" },
    { id: 3, name: "Top Clicks" },
    { id: 4, name: "Top Impressions" },
    { id: 5, name: "Top Followers" },
  ];

  useQuery(GET_INFLUENCERS, {
    onCompleted: (response) => {
      setInfluencers(response.adminGetInfluencers);
    },
  });

  const { data, refetch } = useQuery(GET_CAMPAIGN_RESULTS, {
    fetchPolicy: "network-only",
    variables: {
      userIds: selectedInfluencerIds,
    },
    onCompleted: (response) => {
      if (response) {
        setCampaignResults(response?.getCampaignResults);
        const groupedResults = response.getCampaignResults.reduce(
          (userData: influencerResult, currentResult: CampaignResult) => {
            if (!userData[currentResult.userId]) {
              userData[currentResult.userId] = {
                totalInstaLikes: 0,
                totalTiktokLikes: 0,
                totalInstaReach: 0,
                totalTiktokViews: 0,
                totalInstaImpressions: 0,
                totalInstaComments: 0,
                totalTiktokComments: 0,
                totalInstaLinkClicks: 0,
                campaignResultCount: 0,
                totalInstaFollowers: 0,
                totalTiktokFollowers: 0,
                campaigns: [],
                avgLikes: [],
                avgReach: [],
                avgEngagement: [],
                clicks: [],
                avgImpression: [],
                avgFollowers: [],
                tikAvglike: [],
                tikAvgReach: [],
                tikAvgEngagement: [],
                tikAvgClick: [],
                tikAvgImpression: [],
                tikAvgFollowers: [],
              };
            }

            userData[currentResult?.userId].totalInstaLikes +=
              currentResult?.instaLikes;
            userData[currentResult?.userId].totalTiktokLikes +=
              currentResult?.tiktokLikes;
            userData[currentResult?.userId].totalInstaReach +=
              currentResult?.instaReach;
            userData[currentResult?.userId].totalTiktokViews +=
              currentResult?.tiktokViews;
            userData[currentResult?.userId].totalInstaImpressions +=
              currentResult?.instaImpressions;
            userData[currentResult?.userId].totalInstaComments +=
              currentResult?.instaComments;
            userData[currentResult?.userId].totalTiktokComments +=
              currentResult?.tiktokComments;
            userData[currentResult?.userId].totalInstaLinkClicks +=
              currentResult?.instaLinkClicks;
            userData[currentResult?.userId].totalTiktokFollowers +=
              currentResult?.tiktokFollowers;
            userData[currentResult?.userId].totalInstaFollowers +=
              currentResult?.instaFollowers;
            userData[currentResult?.userId].campaignResultCount += 1;
            if (
              !userData[currentResult?.userId].campaigns.includes(
                currentResult?.campaignId
              )
            ) {
              userData[currentResult?.userId].campaigns.push(
                currentResult?.campaignId
              );
            }

            // insta array
            userData[currentResult?.userId].avgLikes.push(
              currentResult?.instaLikes
            );
            userData[currentResult?.userId].avgReach.push(
              currentResult?.instaReach
            );
            userData[currentResult?.userId].clicks.push(
              currentResult?.instaLinkClicks
            );
            userData[currentResult?.userId].avgEngagement.push(
              currentResult?.instaComments + currentResult?.instaLikes
            );
            userData[currentResult?.userId].avgImpression.push(
              currentResult?.instaImpressions
            );
            userData[currentResult?.userId].avgFollowers.push(
              currentResult?.instaFollowers
            );
            // tiktok Array
            userData[currentResult?.userId].tikAvglike.push(
              currentResult?.tiktokLikes
            );
            userData[currentResult?.userId].tikAvgReach.push(
              currentResult?.tiktokViews
            );
            userData[currentResult?.userId].tikAvgEngagement.push(
              currentResult?.tiktokViews + currentResult?.tiktokLikes
            );
            userData[currentResult?.userId].tikAvgClick.push(
              currentResult?.tiktokViews
            );
            userData[currentResult?.userId].tikAvgImpression.push(
              currentResult?.tiktokViews
            );
            userData[currentResult?.userId].tikAvgFollowers.push(
              currentResult?.tiktokFollowers
            );

            return userData;
          },
          {}
        );

        setInfluencersData(groupedResults);
      }
    },
  });

  useEffect(() => {
    let checkedInfluencers = influencers?.filter((influencer) =>
      selectedInfluencerIds?.includes(influencer.id)
    );
    let influencerNames = checkedInfluencers?.map(
      (influencer) => influencer.name
    );

    if (influencersData) {
      setGraphData([
        [
          calculateReach(selectedInfluencerIds, influencersData, "instagram"),
          calculateReach(selectedInfluencerIds, influencersData, "tiktok"),
        ],
        [
          calculateEngagement(
            selectedInfluencerIds,
            influencersData,
            "instagram"
          ),
          calculateEngagement(selectedInfluencerIds, influencersData, "tiktok"),
        ],
        [
          calculateLikes(selectedInfluencerIds, influencersData, "instagram"),
          calculateLikes(selectedInfluencerIds, influencersData, "tiktok"),
        ],
        [
          calculateClicks(selectedInfluencerIds, influencersData, "instagram"),
          calculateClicks(selectedInfluencerIds, influencersData, "tiktok"),
        ],
        [
          calculateImpressions(
            selectedInfluencerIds,
            influencersData,
            "instagram"
          ),
          calculateImpressions(
            selectedInfluencerIds,
            influencersData,
            "tiktok"
          ),
        ],
        [
          calculateFollowers(
            selectedInfluencerIds,
            influencersData,
            "instagram"
          ),
          calculateFollowers(selectedInfluencerIds, influencersData, "tiktok"),
        ],
      ]);
    }
    setNames(influencerNames);
  }, [campaignResults, influencersData]);

  useEffect(() => {
    if (selectedInfluencerIds) {
      refetch();
    }
  }, [selectedInfluencerIds, refetch]);

  const calculateReach = (
    influencerIds: any[],
    influencersData: influencerResult,
    platform: string
  ) => {
    return influencerIds?.map((id: string) => {
      const currentInfluencer = influencers?.find(
        (influencer) => influencer?.id === id
      );
      let totalReach = 0;
      let campaignData: number[] = [];

      if (platform === "instagram") {
        totalReach = influencersData[id]?.totalInstaReach;
        campaignData = influencersData[id]?.avgReach || [];
      } else if (platform === "tiktok") {
        totalReach = influencersData[id]?.totalTiktokViews;
        campaignData = influencersData[id]?.tikAvgReach || [];
      }

      return {
        y: totalReach / influencersData[id]?.campaignResultCount || 0,
        x: influencersData[id]?.campaignResultCount || 0,
        influencerName: currentInfluencer?.name,
        campaignData,
      };
    });
  };

  const calculateEngagement = (
    influencerIds: any[],
    influencersData: influencerResult,
    platform: string
  ) => {
    return influencerIds?.map((id: string) => {
      const currentInfluencer = influencers?.find(
        (influencer) => influencer?.id === id
      );
      let totalEngagement = 0;
      let campaignData: number[] = [];

      if (platform === "instagram") {
        totalEngagement =
          influencersData[id]?.totalInstaComments +
          influencersData[id]?.totalInstaLikes;
        campaignData = influencersData[id]?.avgEngagement || [];
      } else if (platform === "tiktok") {
        totalEngagement =
          influencersData[id]?.totalTiktokComments +
          influencersData[id]?.totalTiktokLikes;
        campaignData = influencersData[id]?.tikAvgEngagement || [];
      }

      return {
        y: totalEngagement / influencersData[id]?.campaignResultCount || 0,
        x: influencersData[id]?.campaignResultCount || 0,
        influencerName: currentInfluencer?.name,
        campaignData,
      };
    });
  };

  const calculateLikes = (
    influencerIds: any[],
    influencersData: influencerResult,
    platform: string
  ) => {
    return influencerIds?.map((id: string) => {
      const currentInfluencer = influencers?.find(
        (influencer) => influencer?.id === id
      );
      let totalLikes = 0;
      let campaignData: number[] = [];

      if (platform === "instagram") {
        totalLikes = influencersData[id]?.totalInstaLikes;
        campaignData = influencersData[id]?.avgLikes || [];
      } else if (platform === "tiktok") {
        totalLikes = influencersData[id]?.totalTiktokLikes;
        campaignData = influencersData[id]?.tikAvglike || [];
      }

      return {
        y: totalLikes / influencersData[id]?.campaignResultCount || 0,
        x: influencersData[id]?.campaignResultCount || 0,
        influencerName: currentInfluencer?.name,
        campaignData,
      };
    });
  };

  const calculateClicks = (
    influencerIds: any[],
    influencersData: influencerResult,
    platform: string
  ) => {
    return influencerIds?.map((id: string) => {
      const currentInfluencer = influencers?.find(
        (influencer) => influencer?.id === id
      );
      let totalClicks = 0;
      let campaignData: number[] = [];

      if (platform === "instagram") {
        totalClicks = influencersData[id]?.totalInstaLinkClicks;
        campaignData = influencersData[id]?.clicks || [];
      } else if (platform === "tiktok") {
        totalClicks = influencersData[id]?.totalTiktokViews;
        campaignData = influencersData[id]?.tikAvgClick || [];
      }

      return {
        y: totalClicks / influencersData[id]?.campaignResultCount || 0,
        x: influencersData[id]?.campaignResultCount || 0,
        influencerName: currentInfluencer?.name,
        campaignData,
      };
    });
  };

  const calculateImpressions = (
    influencerIds: any[],
    influencersData: influencerResult,
    platform: string
  ) => {
    return influencerIds?.map((id: string) => {
      const currentInfluencer = influencers?.find(
        (influencer) => influencer?.id === id
      );
      let totalImpressions = 0;
      let campaignData: number[] = [];

      if (platform === "instagram") {
        totalImpressions = influencersData[id]?.totalInstaImpressions;
        campaignData = influencersData[id]?.avgImpression || [];
      } else if (platform === "tiktok") {
        totalImpressions = influencersData[id]?.totalTiktokViews;
        campaignData = influencersData[id]?.tikAvgImpression || [];
      }

      return {
        y: totalImpressions / influencersData[id]?.campaignResultCount || 0,
        x: influencersData[id]?.campaignResultCount || 0,
        influencerName: currentInfluencer?.name,
        campaignData,
      };
    });
  };

  const calculateFollowers = (
    influencerIds: any[],
    influencersData: influencerResult,
    platform: string
  ) => {
    return influencerIds?.map((id: string) => {
      const currentInfluencer = influencers?.find(
        (influencer) => influencer?.id === id
      );
      let totalFollowers = 0;
      let campaignData: number[] = [];

      if (platform === "instagram") {
        totalFollowers = influencersData[id]?.totalInstaFollowers;
        campaignData = influencersData[id]?.avgFollowers || [];
      } else if (platform === "tiktok") {
        totalFollowers = influencersData[id]?.totalTiktokFollowers;
        campaignData = influencersData[id]?.tikAvgFollowers || [];
      }

      return {
        y: totalFollowers / influencersData[id]?.campaignResultCount || 0,
        x: influencersData[id]?.campaignResultCount || 0,
        influencerName: currentInfluencer?.name,
        campaignData,
      };
    });
  };

  const extendedOptions: ExtendedOptions = {
    xaxis: {
      categories: names,
    },
    fill: {
      type: "solid",
      colors: colors[selectedGraph],
    },
    stroke: {
      colors: colors[selectedGraph],
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
      },
      shared: false,
      theme: "dark",
      fillSeriesColor: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return tooltipData(data);
      },
    },
  };

  const tooltipData = (tooltipData: influencerResult) => {
    let tooltipContent =
      '<div class="tooltip">' +
      '<div class="heading-tooltip">' +
      '<div style="">' +
      "<strong>" +
      tooltipData.influencerName +
      "</strong>" +
      "</div>" +
      "</div>" +
      '<div class="data-tooltip-main">';

    if (selectedGraph === 0) {
      tooltipContent +=
        '<div style=""><div>' +
        (tooltipData.campaignData as unknown as number[]).map(
          (campaign: any, index) => {
            return (
              `Campaign ${index + 1}: ` +
              "<strong>" +
              campaign +
              "</strong><br>"
            );
          }
        ) +
        "</div></div>" +
        '<div style="data-value">Average Reach:  ' +
        "<strong>" +
        tooltipData?.y?.toLocaleString() +
        "</strong>" +
        "</div>";
    } else if (selectedGraph == 1) {
      tooltipContent +=
        '<div style=""><div>' +
        (tooltipData.campaignData as unknown as number[]).map(
          (campaign: any, index) => {
            return (
              `Campaign ${index + 1}: ` +
              "<strong>" +
              campaign +
              "</strong><br>"
            );
          }
        ) +
        "</div></div>" +
        '<div style="data-value">Average Engagement:  ' +
        "<strong>" +
        tooltipData?.y?.toLocaleString() +
        "</strong>" +
        "</div>";
    } else if (selectedGraph == 2) {
      tooltipContent +=
        '<div style=""><div>' +
        (tooltipData.campaignData as unknown as number[]).map(
          (campaign: any, index) => {
            return (
              `Campaign ${index + 1}: ` +
              "<strong>" +
              campaign +
              "</strong><br>"
            );
          }
        ) +
        "</div></div>" +
        '<div style="data-value">Average Likes:  ' +
        "<strong>" +
        tooltipData?.y?.toLocaleString() +
        "</strong>" +
        "</div>";
    } else if (selectedGraph == 3) {
      tooltipContent +=
        '<div style=""><div>' +
        (tooltipData.campaignData as unknown as number[]).map(
          (campaign: any, index) => {
            return (
              `Campaign ${index + 1}: ` +
              "<strong>" +
              campaign +
              "</strong><br>"
            );
          }
        ) +
        "</div></div>" +
        '<div style="data-value">Average Clicks:  ' +
        "<strong>" +
        tooltipData?.y?.toLocaleString() +
        "</strong>" +
        "</div>";
    } else if (selectedGraph == 4) {
      tooltipContent +=
        '<div style=""><div>' +
        (tooltipData.campaignData as unknown as number[]).map(
          (campaign: any, index) => {
            return (
              `Campaign ${index + 1}: ` +
              "<strong>" +
              campaign +
              "</strong><br>"
            );
          }
        ) +
        "</div></div>" +
        '<div style="data-value">Average Impressions:  ' +
        "<strong>" +
        tooltipData?.y?.toLocaleString() +
        "</strong>" +
        "</div>";
    } else if (selectedGraph == 5) {
      tooltipContent +=
        '<div style=""><div>' +
        (tooltipData.campaignData as unknown as number[]).map(
          (campaign: any, index) => {
            return (
              `Campaign ${index + 1}: ` +
              "<strong>" +
              campaign +
              "</strong><br>"
            );
          }
        ) +
        "</div></div>" +
        '<div style="data-value">Average Followers:  ' +
        "<strong>" +
        tooltipData?.y?.toLocaleString() +
        "</strong>" +
        "</div>";
    }

    tooltipContent += "</div>" + "</div>";

    return tooltipContent;
  };

  const handleChange = (event: SelectChangeEvent) => {
    const option = event.target.value;
    setSelectedGraph(parseInt(option));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (graphData?.length > 0) {
    series = [
      {
        name: graphToShow[selectedGraph][0],
        data: graphData[selectedGraph][0],
      },
      {
        name: graphToShow[selectedGraph][1],
        data: graphData[selectedGraph][1],
      },
    ];
  }

  return (
    <div>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          color: "#fff",
          paddingLeft: 3,
        }}
      >
        <Tabs
          value={value}
          onChange={handleTabChange as any}
          aria-label="chart tabs"
          variant="scrollable" // Make the tabs scrollable
          scrollButtons="auto" // Show scroll buttons when needed
        >
          <Tab label="Campaign Cost Breakdown" />
          <Tab label="Influencer Graph" />
          <Tab label="Campaign Comparison" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={2}>
        <Typography
          variant="h4"
          align="center"
          sx={{ marginTop: 2, alignSelf: "center" }}
        >
          Metrics Listing
        </Typography>
        <Grid
          container // This turns the Grid into a flex container
          direction="row"
          justifyContent="space-between" // This will put maximum space between the Typography and Select
          alignItems="center" // This will vertically align the Typography and Select to the center of the row
          sx={{ marginBottom: 2, marginTop: 3 }}
        >
          <Typography
            variant="h5"
            sx={{ width: `${width * 0.6}px`, marginLeft: 2 }}
          >
            {graphToShow[selectedGraph][2]}
          </Typography>
          <Select
            value={selectedGraph?.toString()}
            onChange={handleChange}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Comparison Type"
            variant="standard"
            sx={{
              color: "#FFF",
              background: "#FFA3C7",
              borderRadius: 1,
              padding: 1,
              width: `${width * 0.15}px`,
              marginRight: 2,
            }}
          >
            {graphType?.map((graph) => (
              <MenuItem value={graph.id}>{graph.name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={8} sx={{ display: "flex" }}>
          <Chart
            options={
              {
                ...options,
                ...extendedOptions,
              } as any
            }
            series={series as any}
            type="scatter"
            height={height / 1.4}
            width={width * 0.8}
          />
          <FormControl sx={{ margin: 2, width: `${width * 0.15}px` }}>
            {influencers && (
              <MultiSelectDropdown
                selectFor="Influencers"
                list={influencers}
                setSelectedIds={setSelectedInfluencerIds}
              />
            )}
          </FormControl>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChartWrapper
          userRole={"Admin"}
          chart={null}
          imageSrc={null}
          altText={null}
          component={<InfluencerPerformanceChart />}
        />
      </TabPanel>
      <TabPanel value={value} index={0}>
        <ChartWrapper
          userRole={"Admin"}
          chart={null}
          imageSrc={null}
          altText={null}
          component={<CampaignCostBreakdown />}
        />
      </TabPanel>
    </div>
  );
};

export default MetricsGraphs;
