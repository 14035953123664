import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const client = new ApolloClient({
  uri: "https://api.testauthentic.co.uk/graphql",
  // uri: "https://authenticusers.co/graphql",
  // uri: "http://localhost:3000/graphql",
  // uri: "https://shrouded-dusk-40789-82873da8bdd3.herokuapp.com/graphql",

  headers: {
    accessToken: localStorage.getItem("accessToken") || "",
    client: localStorage.getItem("client") || "",
    uid: localStorage.getItem("uid") || "",
  },
  cache: new InMemoryCache(),
});

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);
