import {useQuery} from "@apollo/client";
import {
	Box,
	Button,
	CardMedia,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Tab,
	Tabs,
	TextField,
	Typography,
} from "@mui/material";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import InfluencerGallery from "../components/influencerDetailsComponents/influencerGallery";
import InsightsSection from "../components/influencerDetailsComponents/insightsSection";
import MultiSelectDropdown from "../components/SharedComponents/multiSelectDropdown";
import GET_CATEGORIES from "../graphql/queries/getCategories";
import GET_SINGLE_INFLUENCER from "../graphql/queries/getSingleInfluencer";
import InfluencerWishlist from "../components/influencerDetailsComponents/influencerWishlist";
import CampaignResult from "./campaignResults";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const {children, value, index, ...other} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{p: 3}}>
					<>{children}</>
				</Box>
			)}
		</div>
	);
}

const countries = ["England", "Northern Ireland", "Scotland", "Wales"];
const cities: any = {
	England: [
		"Bath",
		"Birmingham",
		"Bradford",
		"Brighton and Hove",
		"Bristol",
		"Cambridge",
		"Canterbury",
		"Carlisle",
		"Chelmsford",
		"Chester",
		"Chichester",
		"Colchester",
		"Coventry",
		"Derby",
		"Doncaster",
		"Durham",
		"Ely",
		"Exeter",
		"Gloucester",
		"Hereford",
		"Kingston upon Hull",
		"Lancaster",
		"Leeds",
		"Leicester",
		"Lichfield",
		"Lincoln",
		"Liverpool",
		"London",
		"Manchester",
		"Milton Keynes",
		"Newcastle upon Tyne",
		"Norwich",
		"Nottingham",
		"Oxford",
		"Peterborough",
		"Plymouth",
		"Portsmouth",
		"Preston",
		"Ripon",
		"Salford",
		"Salisbury",
		"Sheffield",
		"Southampton",
		"Southend-on-Sea",
		"St Albans",
		"Stoke-on-Trent",
		"Sunderland",
		"Truro",
		"Wakefield",
		"Wells",
		"Westminster",
		"Winchester",
		"Wolverhampton",
		"Worcester",
		"York",
	],
	"Northern Ireland": [
		"Armagh",
		"Bangor",
		"Belfast",
		"Lisburn",
		"Londonderry",
		"Newry",
	],
	Scotland: [
		"Aberdeen",
		"Dundee",
		"Dunfermline",
		"Edinburgh",
		"Glasgow",
		"Inverness",
		"Perth",
		"Stirling",
	],
	Wales: [
		"Bangor",
		"Cardiff",
		"Newport",
		"St Asaph (Llanelwy)",
		"St Davids",
		"Swansea",
		"Wrexham",
	],
};
const InfluencerDetails = () => {
	const {id} = useParams();
	const [value, setValue] = React.useState(0);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState<any>("");
	const [selectedCity, setSelectedCity] = useState("");

	const {data} = useQuery(GET_SINGLE_INFLUENCER, {
		variables: {influencerId: id},
		onCompleted: (resp) => {
			const categoryIds = resp?.adminGetSingleInfluencer?.categories?.map(
				(category: any) => category.id
			);

			setSelectedCategories(categoryIds);
			setSelectedCountry(resp.adminGetSingleInfluencer.country);
			setSelectedCity(resp.adminGetSingleInfluencer.city);
		},
	});
	const {data: categories} = useQuery(GET_CATEGORIES);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	if (!data) return <></>;
	return (
		<Box
			sx={{
				flexGrow: 1,
				bgcolor: "background.paper",
				display: "flex",
				height: 224,
			}}
		>
			<Tabs
				orientation="vertical"
				variant="scrollable"
				value={value}
				onChange={handleChange}
				aria-label="Vertical tabs example"
				sx={{borderRight: 1, borderColor: "divider", minWidth: 200}}
			>
				<Tab label="Details"/>
				<Tab label="Gallery"/>
				<Tab label="Insights"/>
				<Tab label="Campaign Results"/>
				<Tab label="Survey Responses"/>
				<Tab label="Wishlist"/>
			</Tabs>
			<TabPanel value={value} index={0}>
				<CardMedia
					id="user-profile-pic"
					component="img"
					sx={{width: 151, marginBottom: 3}}
					image={
						data.adminGetSingleInfluencer.image ||
						"https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Unknown_person.jpg/640px-Unknown_person.jpg"
					}
					alt={data.adminGetSingleInfluencer.name}
				/>
				<TextField
					id="name"
					label="Name"
					defaultValue={data.adminGetSingleInfluencer.name}
					variant="standard"
					sx={{margin: 2, width: 200}}
				/>
				<TextField
					id="email"
					label="Email"
					defaultValue={data.adminGetSingleInfluencer.email}
					variant="standard"
					sx={{margin: 2, width: 300}}
				/>
				<TextField
					id="tiktok"
					label="TikTok"
					defaultValue={data.adminGetSingleInfluencer.tiktok}
					variant="standard"
					sx={{margin: 2}}
				/>
				<TextField
					id="instagram"
					label="Instagram"
					defaultValue={data.adminGetSingleInfluencer.instagram}
					variant="standard"
					sx={{margin: 2}}
				/>
				{categories && (
					<MultiSelectDropdown
						selectFor="Categories"
						list={categories?.adminGetCategories}
						setSelectedIds={setSelectedCategories}
					/>
				)}
				<FormControl sx={{margin: 2, width: 300}}>
					<InputLabel id="country-select-label">
						Select Country / Region
					</InputLabel>
					<Select
						labelId="country-select-label"
						id="country-select"
						label="Country / Region"
						variant="standard"
						defaultValue={data.adminGetSingleInfluencer.country}
						onChange={(e: any) => setSelectedCountry(e.target.value)}
					>
						{countries.map((country) => (
							<MenuItem value={country}>{country}</MenuItem>
						))}
					</Select>
				</FormControl>
				{selectedCountry && (
					<FormControl fullWidth sx={{margin: 2, width: 300}}>
						<InputLabel id="city-select-label">Select City</InputLabel>
						<Select
							labelId="city-select-label"
							id="city-select"
							label="City"
							variant="standard"
							defaultValue={data.adminGetSingleInfluencer.city}
							onChange={(e: any) => setSelectedCity(e.target.value)}
						>
							{cities[selectedCountry]?.map((city: string) => (
								<MenuItem value={city}>{city}</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
				<br/>
				<Button variant="contained"> Update </Button>
				<Typography variant="caption" color="error" sx={{marginLeft: 2}}>
					<b>Note:</b> Only press "Update" if you change a field
				</Typography>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<InfluencerGallery id={id!}/>
			</TabPanel>
			<TabPanel value={value} index={2}>
				<InsightsSection id={id!}/>
			</TabPanel>
			<TabPanel value={value} index={3}>
				<CampaignResult id={id}/>
			</TabPanel>
			<TabPanel value={value} index={4}>
				Survey Responses
			</TabPanel>
			<TabPanel value={value} index={5}>
				<InfluencerWishlist id={id!}/>
			</TabPanel>
		</Box>
	);
};

export default InfluencerDetails;
