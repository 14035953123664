import {Grid, Typography, Tabs, Tab} from "@mui/material";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React, {useState} from "react";
import {useQuery} from "@apollo/client";
import {
    GET_BRAND_CAMPAIGN_USERS_DATA,
} from "../graphql/queries/getBrandCampaignNames";

import {GET_CAMPAIGNS} from "../graphql/queries/getCampaignsForResults";

const TabPanel = (props) => {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const CampaignResult = (props) => {
    const [campaign, setCampaign] = useState(null);
    const [results, setResults] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);

    const {data: userData, refetch: refetchUserData} = useQuery(
        GET_BRAND_CAMPAIGN_USERS_DATA,
        {
            skip: campaign === null,
            variables: {
                campaignId: campaign,
            },
            onCompleted: (res) => {
                res.getBrandCampaignUsersData &&
                setResults(res.getBrandCampaignUsersData.results);
            },
        }
    );

    const {data: _campaigns} = useQuery(GET_CAMPAIGNS, {
        variables: {
            userId: props.id,
        },
    });

    const instaSums = results !== null ? {
        likes: results.reduce((acc, row) => acc + (row.type === 'instagram' ? row.instagram[0] : 0), 0),
        comments: results.reduce((acc, row) => acc + (row.type === 'instagram' ? row.instagram[1] : 0), 0),
        reach: results.reduce((acc, row) => acc + (row.type === 'instagram' ? row.instagram[2] : 0), 0),
        views: results.reduce((acc, row) => acc + (row.type === 'instagram' ? row.instagram[3] : 0), 0),
        impressions: results.reduce((acc, row) => acc + (row.type === 'instagram' ? row.instagram[4] : 0), 0),
        followers: results.reduce((acc, row) => acc + (row.type === 'instagram' ? row.instagram[5] : 0), 0),
        shares: results.reduce((acc, row) => acc + (row.type === 'instagram' ? row.instagram[6] : 0), 0),
        saves: results.reduce((acc, row) => acc + (row.type === 'instagram' ? row.instagram[7] : 0), 0)

    } : null;

    const tiktokSums = results !== null ? {
        likes: results.reduce((acc, row) => acc + (row.type === 'tiktok' ? parseInt(row.tiktok[0]) : 0), 0),
        comments: results.reduce((acc, row) => acc + (row.type === 'tiktok' ? parseInt(row.tiktok[1]) : 0), 0),
        reach: results.reduce((acc, row) => acc + (row.type === 'tiktok' ? parseInt(row.tiktok[2]) : 0), 0),
        views: results.reduce((acc, row) => acc + (row.type === 'tiktok' ? parseInt(row.tiktok[3]) : 0), 0),
        plays: results.reduce((acc, row) => acc + (row.type === 'tiktok' ? parseInt(row.tiktok[4]) : 0), 0),
        shares: results.reduce((acc, row) => acc + (row.type === 'tiktok' ? parseInt(row.tiktok[5]) : 0), 0),
        saves: results.reduce((acc, row) => acc + (row.type === 'tiktok' ? parseInt(row.tiktok[6]) : 0), 0),

    } : null;

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="start"
        >

            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="details-switch"
                sx={{maxWidth: 280}}
            >
            </Tabs>
            <Grid item xs={11} container justifyContent="space-between">
                <Box sx={{minWidth: 200}}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Campaign</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={campaign || ""}
                            label="Campaign"
                            onChange={(e) => setCampaign(e.target.value)}
                            variant="standard"
                        >
                            {_campaigns?.getMyCampaignsForResults &&
                                _campaigns.getMyCampaignsForResults.map((campaign) => (
                                    <MenuItem key={campaign.id} value={campaign.id}>
                                        {campaign.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={11} sx={{marginTop: 5}}>
                <TabPanel value={selectedTab} index={0}>
                    <TableContainer component={Paper} sx={{marginBottom: 5}}>
                        <Typography textAlign="center" fontWeight="bold"> Instagram </Typography>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Campaign Type</TableCell>
                                    <TableCell>Influencer</TableCell>
                                    <TableCell align="center">Total Likes</TableCell>
                                    <TableCell align="center">Total Comments</TableCell>
                                    <TableCell align="center">Total Reach</TableCell>
                                    <TableCell align="center">Total Plays</TableCell>
                                    <TableCell align="center">Total Impressions</TableCell>
                                    <TableCell align="center">Followers</TableCell>
                                    <TableCell align="center">Total Shares</TableCell>
                                    <TableCell align="center">Total Saves</TableCell>
                                    <TableCell align="center">Discount Code Used</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {results !== null &&
                                    results.map((row) => row.type === 'instagram' && (
                                        <TableRow
                                            key={row.id}
                                            sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.date}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.campaign_type}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="center">{row.instagram[0]}</TableCell>
                                            <TableCell align="center">{row.instagram[1]}</TableCell>
                                            <TableCell align="center">{row.instagram[2]}</TableCell>
                                            <TableCell align="center">{row.instagram[3]}</TableCell>
                                            <TableCell align="center">{row.instagram[4]}</TableCell>
                                            <TableCell align="center">{row.instagram[5]}</TableCell>
                                            <TableCell align="center">{row.instagram[6]}</TableCell>
                                            <TableCell align="center">{row.instagram[7]}</TableCell>
                                            <TableCell align="center">No</TableCell>
                                        </TableRow>
                                    ))}
                                {
                                    instaSums !== null &&
                                    <TableRow
                                        sx={{"&:last-child td, &:last-child th": {border: 0}, fontWeight: 'bold'}}
                                    >
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>
                                            Total
                                        </TableCell>
                                        <TableCell align="center"
                                                   sx={{fontWeight: 'bold'}}>{instaSums.likes}</TableCell>
                                        <TableCell align="center"
                                                   sx={{fontWeight: 'bold'}}>{instaSums.comments}</TableCell>
                                        <TableCell align="center"
                                                   sx={{fontWeight: 'bold'}}>{instaSums.reach}</TableCell>
                                        <TableCell align="center"
                                                   sx={{fontWeight: 'bold'}}>{instaSums.views}</TableCell>
                                        <TableCell align="center"
                                                   sx={{fontWeight: 'bold'}}>{instaSums.impressions}</TableCell>
                                        <TableCell align="center"
                                                   sx={{fontWeight: 'bold'}}>{instaSums.followers}</TableCell>
                                        <TableCell align="center"
                                                   sx={{fontWeight: 'bold'}}>{instaSums.shares}</TableCell>
                                        <TableCell align="center"
                                                   sx={{fontWeight: 'bold'}}>{instaSums.saves}</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer component={Paper} sx={{marginBottom: 5}}>
                        <Typography textAlign="center" fontWeight="bold"> Tiktok </Typography>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Campaign Type</TableCell>
                                    <TableCell>Influencer</TableCell>
                                    <TableCell align="center">Total Likes</TableCell>
                                    <TableCell align="center">Total Comments</TableCell>
                                    <TableCell align="center">Total Avg. Watch Time</TableCell>
                                    <TableCell align="center">Total Watch Full Video Time</TableCell>
                                    <TableCell align="center">Total Plays</TableCell>
                                    <TableCell align="center">Total Shares</TableCell>
                                    <TableCell align="center">Total Saves</TableCell>
                                    <TableCell align="center">Discount Code Used</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {results !== null &&
                                    results.map((row) => row.type === 'tiktok' && (
                                        <TableRow
                                            key={row.id}
                                            sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.date}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.campaign_type}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="center">{row.tiktok[0]}</TableCell>
                                            <TableCell align="center">{row.tiktok[1]}</TableCell>
                                            <TableCell align="center">{row.tiktok[2]}</TableCell>
                                            <TableCell align="center">{row.tiktok[3]}</TableCell>
                                            <TableCell align="center">{row.tiktok[4]}</TableCell>
                                            <TableCell align="center">{row.tiktok[5]}</TableCell>
                                            <TableCell align="center">{row.tiktok[6]}</TableCell>
                                            <TableCell align="center">No</TableCell>
                                        </TableRow>
                                    ))}
                                {
                                    tiktokSums !== null &&
                                    <TableRow
                                        sx={{"&:last-child td, &:last-child th": {border: 0}, fontWeight: 'bold'}}
                                    >
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>
                                            Total
                                        </TableCell>
                                        <TableCell align="center"
                                                   sx={{fontWeight: 'bold'}}>{tiktokSums.likes}</TableCell>
                                        <TableCell align="center"
                                                   sx={{fontWeight: 'bold'}}>{tiktokSums.comments}</TableCell>
                                        <TableCell align="center"
                                                   sx={{fontWeight: 'bold'}}>{tiktokSums.reach}</TableCell>
                                        <TableCell align="center"
                                                   sx={{fontWeight: 'bold'}}>{tiktokSums.views}</TableCell>
                                        <TableCell align="center"
                                                   sx={{fontWeight: 'bold'}}>{tiktokSums.plays}</TableCell>
                                        <TableCell align="center"
                                                   sx={{fontWeight: 'bold'}}>{tiktokSums.shares}</TableCell>
                                        <TableCell align="center"
                                                   sx={{fontWeight: 'bold'}}>{tiktokSums.saves}</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default CampaignResult;
