import * as React from "react";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import REMOVE_INFLUENCER from "../../graphql/mutations/adminRemoveInfluencerFromCampaign";
import UPDATE_CAMPAIGN_CONTRACT from "../../graphql/mutations/updateCampaignContract";
import GET_CAMPAIGN_DETAILS from "../../graphql/queries/getCampaignDetails"; 
import CampaignContract from './CampaignContract'; 
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  Typography,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardActions,
  CardContent,
  CardMedia,
} from "@mui/material";

interface ContractDetails {
  id: string;
  influencer: { id: string; name: string; image: string };
  description: string;
  contractType: number;
  influencerQuote: number;
  brandQuote: number;
  finalFee: number;
  commissionModel: boolean;
}

const MiniInfluencerCard: React.FC<{ contractDetails: ContractDetails; campaign: any }> = ({ contractDetails, campaign }) => {
  const { id } = useParams<{ id: string }>();
  const [removeInfluencer] = useMutation(REMOVE_INFLUENCER, {
    refetchQueries: ["getCampaignDetails"],
  });

  const [commissionModel, setCommissionModel] = useState(contractDetails.commissionModel)

  const [updateCampaignContract] = useMutation(UPDATE_CAMPAIGN_CONTRACT, {
    refetchQueries: ["getCampaignDetails"],
  });

  const [showContractModal, setShowContractModal] = useState(false); 

  const updateCommisionModel = (updatedCommissionModel: boolean) => {
    setCommissionModel(updatedCommissionModel);
    updateCampaignContract({
      variables: {
        campaignContractId: id,
        commissionModel: updatedCommissionModel,
      },
    });
  };

  const handleShowContractModal = () => {
    setShowContractModal(true);
  };

  const handleCloseContractModal = () => {
    setShowContractModal(false);
  };

  return (
    <>
      <Card sx={{ width: 300, marginBottom: 2, marginRight: 2 }}>
        <CardMedia
          sx={{ height: 300 }}
          image={
            contractDetails?.influencer?.image ||
            "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930"
          }
          title="green iguana"
        />
        <CardContent>
          <Typography
            variant="caption"
            color={contractDetails?.contractType === 0 ? "error" : "success.dark"}
            mb={1}
          >
            {contractDetails?.contractType === 0 ? "Invited" : "Interested"}
          </Typography>
          <Typography gutterBottom variant="h5" component="div">
            {contractDetails?.influencer?.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" mb={1}>
            {contractDetails?.description || "No work brief provided..."}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <b>Influencer Quote:</b> &emsp; £
            {contractDetails?.influencerQuote || "-"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <b>Brand Quote:</b> &emsp; £{contractDetails?.brandQuote || "-"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <b>Final Fee:</b> £&emsp; {contractDetails?.finalFee || "-"}
          </Typography>

          {campaign.commissionModel && (
            <FormControl sx={{ width: "100%", marginTop: 2, padding: 0.5 }}>
              <InputLabel
                id="opt-in-for-the-affiliate-commission-model"
                sx={{ color: "#EF2E83", fontSize: "1rem !important" }}
              >
                Opt in for the Affiliate commission model
              </InputLabel>
              <Select
                variant="standard"
                labelId="opt-in-for-the-affiliate-commission-model"
                id="opt-in-for-the-affiliate-commission"
                label="Opt in for the Affiliate commission model"
                value={contractDetails.commissionModel}
                onChange={(e) =>
                  updateCommisionModel(e.target.value === 'true')
                }
              >
                {[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }].map((option) => (
                  <MenuItem
                    value={option.value}
                    key={option.value + "commission-model" + option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </CardContent>
        <CardActions>
          <Button
            size="small"
            variant="outlined"
            onClick={() =>
              removeInfluencer({
                variables: {
                  userId: contractDetails?.influencer?.id,
                  campaignId: id,
                },
              })
            }
          >
            Remove Influencer
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={handleShowContractModal}
          >
            View Contract & Signatures
          </Button>
        </CardActions>
      </Card>

      {/* Contract and Signatures Modal */}
      <Modal
        open={showContractModal}
        onClose={handleCloseContractModal}
        aria-labelledby="contract-and-signatures-modal"
        aria-describedby="contract-and-signatures-modal-description"
      >
        <CampaignContract
          contractDetails={contractDetails}
          onClose={handleCloseContractModal}
        />
      </Modal>
    </>
  );
};

export default MiniInfluencerCard;
