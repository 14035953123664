import {
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import useWindowDimensions from "./customHooks/useWindowDimentions";
import { useQuery, useLazyQuery } from "@apollo/client";
import GET_BRANDS from "../graphql/queries/getBrands";
import { GET_BRAND_CAMPAIGN_NAMES, GET_BRAND_CAMPAIGN_USERS_DATA } from "../graphql/queries/getBrandCampaignNames";

const Selector = ({ label, handleChange, items }) => {
  return (
    <FormControl fullWidth>
      <InputLabel
        id="demo-simple-select-label"
        sx={{ color: "#FFF", fontSize: "1rem !important" }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Campaign"
        variant="standard"
        sx={{
          color: "#FFF",
          background: "#FFA3C7",
          borderRadius: 1,
          padding: 1,
        }}
        onChange={handleChange}
      >
        {items.map((item) => (
          <MenuItem value={item.id}>{item.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const tiktokLabels = ["Likes", "Comments", "Average Watch Time", "Watch Full Video", "Plays", "Shares", "Saves"]
const instagramLabels = ["Likes", "Comments", "Reach", "Plays", "Impressions", "Followers", "Shares", "Save"]

const CampaignCostBreakdown = () => {
  const [labels, setLabels] = useState([])
  const [campaignType, setCampaignType] = useState('')
  const [brands, setBrands] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [campaignResults, setCampaignResults] = useState(null)
  const { height, width } = useWindowDimensions();
  const [series, setSeries] = useState([0, 0, 0, 0, 0, 0, 0]);

  useQuery(GET_BRANDS, {
    onCompleted: (res) => {
      setBrands(res.adminGetBrands);
    },
  });

  const [getBrandCampaigns] = useLazyQuery(GET_BRAND_CAMPAIGN_NAMES, {
    onCompleted: (res) => {
      setCampaigns(res.getBrandCampaigns)
    },
  });

  const [getBrandCampaignsResults] = useLazyQuery(GET_BRAND_CAMPAIGN_USERS_DATA, {
    onCompleted: (res) => {
      const campaignResults = res.getBrandCampaignUsersData.results;

      const instaStatsSum = [0, 0, 0, 0, 0, 0, 0, 0];
      const tiktokStatsSum = [0, 0, 0, 0, 0, 0, 0];

      campaignResults.forEach((campaign) => {
        if (campaign.instagram) {
          campaign.instagram.forEach((value, index) => {
            instaStatsSum[index] += Number(value) || 0;
          });
        }

        if (campaign.tiktok) {
          campaign.tiktok.forEach((value, index) => {
            tiktokStatsSum[index] += Number(value) || 0;
          });
        }
      });

      console.log("Instagram Stats Sum:", instaStatsSum);
      console.log("TikTok Stats Sum:", tiktokStatsSum);

      // Optionally, store the aggregated results in state
      setCampaignResults({
        original: campaignResults,
        aggregated: {
          instagram: instaStatsSum,
          tiktok: tiktokStatsSum,
        },
      });
    },
  });

  const handleCampaignTypeChange = ({ target: { value } }) => setCampaignType(value)
  const handleCampaignChange = async ({ target: { value } }) => await getBrandCampaignsResults({ variables: { campaignId: value } })
  const handleBrandChange = ({ target: { value } }) => getBrandCampaigns({ variables: { brandId: value } });

  useEffect(() => {
    if (campaignResults && campaignType) {
      setLabels(campaignType === 'Instagram' ? instagramLabels : tiktokLabels)
      setSeries(campaignType === 'Instagram' ? campaignResults.aggregated.instagram : campaignResults.aggregated.tiktok)
    }
  }, [campaignResults, campaignType])

  const options = {
    labels: labels.length ? labels : tiktokLabels,
    legend: {
      fontSize: "16px",
      labels: {
        colors: undefined,
        useSeriesColors: true,
      },
    },
  };

  return (
    <Grid item xs container justifyContent="center">
      <Grid item xs={11} container justifyContent="space-between">
        <Typography></Typography>
        <Box sx={{ minWidth: 200, marginBottom: '20px' }}>
          <Selector
            label='Select Brand'
            handleChange={handleBrandChange}
            items={brands.map(brand => ({ name: brand.brandName, id: brand.id }))}
          />

          <Selector
            label='Select Campaigns'
            handleChange={handleCampaignChange}
            items={campaigns.map(campaign => ({ name: campaign.name, id: campaign.id }))}
          />

          <Selector
            label='Select Campaigns Type'
            handleChange={handleCampaignTypeChange}
            items={['Tiktok', 'Instagram'].map(type => ({ name: type, id: type }))}
          />
        </Box>
      </Grid>

      <Chart
        options={options}
        series={series}
        type="donut"
        height={height / 1.5}
        width={width - 80}
      />
    </Grid>
  );
};

export default CampaignCostBreakdown;
