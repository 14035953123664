import {
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import useWindowDimensions from "./customHooks/useWindowDimentions";
import { useQuery } from "@apollo/client";
import GET_CATEGORIES from "../graphql/queries/getCategories";
import GET_CAMPAIGNS_FOR_CATEGORY from "../graphql/queries/getCampaignsForCategory";
import GET_CAMPAIGN_RESULTS_BY_ID from "../graphql/queries/getCampaignResultsById";
import GET_INFLUENCER_FOR_GRAPH from "../graphql/queries/getInfluencersForGraph";

const InfluencerPerformanceChart = () => {
  const { height, width } = useWindowDimensions();
  const [series, setSeries] = useState([]);
  const [campaignData, setCampaignData] = useState({});
  const [updatedOptions, setUpdatedOptions] = useState({
    chart: {
      type: "donut",
    },
    legend: {
      fontSize: "16px",
      labels: {
        colors: undefined,
        useSeriesColors: true,
      },
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter: (val, { seriesIndex, w }) => {
          const influencerId = userList[seriesIndex];
          const influencerName = userNames[influencerId] || influencerId;
          const campaignStats = campaignData[influencerId] || [];
          const campaignStatsString = campaignStats
            .map(({ campaign, stats }) => {
              const statValue = stats[selectedStat];
              return `<div>Campaign ${campaign}: ${statValue}</div>`;
            })
            .join("");
          console.log(
            `Tooltip data for influencer ${influencerId}:`,
            campaignStats
          );
          return `<div><strong>${influencerName}</strong></div><div>Total: ${val}<br/>${campaignStatsString}</div>`;
        },
      },
      x: {
        formatter: (val) => val,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedStat, setSelectedStat] = useState("instaFollowers");
  const [categoryList, setCategoryList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userNames, setUserNames] = useState({});
  const [cumulativeData, setCumulativeData] = useState({});

  // Fetch categories
  const { data: categoriesData } = useQuery(GET_CATEGORIES, {
    onCompleted: (res) => {
      setCategoryList(res.adminGetCategories);
    },
  });

  // Fetch campaigns for selected category
  const { data: campaignsData, refetch: refetchCampaigns } = useQuery(
    GET_CAMPAIGNS_FOR_CATEGORY,
    {
      fetchPolicy: "network-only",
      variables: { categoryId: selectedCategory },
      skip: !selectedCategory,
    }
  );

  // Fetch influencers for campaigns
  const { refetch: refetchInfluencers } = useQuery(GET_INFLUENCER_FOR_GRAPH, {
    fetchPolicy: "network-only",
    skip: true,
  });

  // Function to fetch campaign results one by one
  const fetchCampaignResults = async (campaignIds) => {
    const allUsersData = {};
    const userCampaignCount = {}; // Track the number of campaigns for each user
    const campaignStats = {};
    const { data } = await refetchCampaignResults({ campaignIds });
    const campaignResults = data.getCampaignResults;
    campaignResults.forEach((result) => {
      if (!allUsersData[result.userId]) {
        allUsersData[result.userId] = {
          instaFollowers: 0,
          instaLikes: 0,
          instaImpressions: 0,
          instaReach: 0,
          instaSaves: 0,
          instaShares: 0,
          instaStoryViews: 0,
          tiktokComments: 0,
          tiktokLikes: 0,
          tiktokPlays: 0,
          tiktokSaves: 0,
          tiktokShares: 0,
          tiktokViews: 0,
        };
        userCampaignCount[result.userId] = 0; // Initialize the campaign count
        campaignStats[result.userId] = []; // Initialize campaign stats array for the user
      }
      userCampaignCount[result.userId] += 1; // Increment the campaign count

      allUsersData[result.userId].instaFollowers += result.instaFollowers;
      allUsersData[result.userId].instaLikes += result.instaLikes;
      allUsersData[result.userId].instaImpressions += result.instaImpressions;
      allUsersData[result.userId].instaReach += result.instaReach;
      allUsersData[result.userId].instaSaves += result.instaSaves;
      allUsersData[result.userId].instaShares += result.instaShares;
      allUsersData[result.userId].instaStoryViews += result.instaStoryViews;
      allUsersData[result.userId].tiktokComments += result.tiktokComments;
      allUsersData[result.userId].tiktokLikes += result.tiktokLikes;
      allUsersData[result.userId].tiktokPlays += result.tiktokPlays;
      allUsersData[result.userId].tiktokSaves += result.tiktokSaves;
      allUsersData[result.userId].tiktokShares += result.tiktokShares;
      allUsersData[result.userId].tiktokViews += result.tiktokViews;

      // Add campaign-specific stats to the campaignStats array
      campaignStats[result.userId].push({
        campaign: result.id,
        stats: {
          instaFollowers: result.instaFollowers,
          instaLikes: result.instaLikes,
          instaImpressions: result.instaImpressions,
          instaReach: result.instaReach,
          instaSaves: result.instaSaves,
          instaShares: result.instaShares,
          instaStoryViews: result.instaStoryViews,
          tiktokComments: result.tiktokComments,
          tiktokLikes: result.tiktokLikes,
          tiktokPlays: result.tiktokPlays,
          tiktokSaves: result.tiktokSaves,
          tiktokShares: result.tiktokShares,
          tiktokViews: result.tiktokViews,
        },
      });
    });

    // Calculate the average for each user
    Object.keys(allUsersData).forEach((userId) => {
      Object.keys(allUsersData[userId]).forEach((stat) => {
        allUsersData[userId][stat] /= userCampaignCount[userId];
      });
    });

    setCumulativeData(allUsersData);
    setCampaignData(campaignStats);
    const userIds = Object.keys(allUsersData);
    updateSeries(allUsersData, userIds, selectedStat);
    setUserList(userIds);
  };

  const { refetch: refetchCampaignResults } = useQuery(
    GET_CAMPAIGN_RESULTS_BY_ID,
    {
      fetchPolicy: "network-only",
      skip: true,
    }
  );

  useEffect(() => {
    if (campaignsData && selectedCategory) {
      const campaignIds = campaignsData.getCampaignsForCategory.map(
        (c) => c.id
      );
      fetchCampaignResults(campaignIds);
    }
  }, [campaignsData, selectedCategory]);

  useEffect(() => {
    if (campaignsData && selectedCategory) {
      const campaignIds = campaignsData.getCampaignsForCategory.map(
        (c) => c.id
      );
      fetchInfluencersForCampaigns(campaignIds);
    }
  }, [userList, selectedCategory]);

  const fetchInfluencersForCampaigns = async (campaignIds) => {
    const { data } = await refetchInfluencers({ campaignIds });
    const newUserList = [...userList];
    if (data) {
      data.getInfluencersForCampaigns.map((result) => {
        const index = newUserList.indexOf(result.id);
        if (index !== -1) {
          newUserList[index] = result.name;
        }
      });
      setUserList(newUserList);
      setUpdatedOptions((prevOptions) => ({
        ...prevOptions,
        labels: newUserList,
      }));
    }
  };

  const updateSeries = (usersData, userIds, stat) => {
    const newSeries = userIds.map((userId) => usersData[userId][stat]);
    setSeries(newSeries);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleStatChange = (event) => {
    setSelectedStat(event.target.value);
    if (cumulativeData) {
      updateSeries(
        cumulativeData,
        Object.keys(cumulativeData),
        event.target.value
      );
    }
  };

  useEffect(() => {
    console.log("Campaign data:", campaignData);
    setUpdatedOptions((prevOptions) => ({
      ...prevOptions,
      tooltip: {
        ...prevOptions.tooltip,
        y: {
          formatter: (val, { seriesIndex, w }) => {
            const influencerId = userList[seriesIndex];
            const influencerName = userNames[influencerId] || influencerId;
            const campaignStats = campaignData[influencerId] || [];
            const campaignStatsString = campaignStats
              .map(({ campaign, stats }) => {
                const statValue = stats[selectedStat];
                return `<div>Campaign ${campaign}: ${statValue}</div>`;
              })
              .join("");
            console.log(
              `Tooltip data for influencer ${influencerId}:`,
              campaignStats
            );
            return `
            <div>
              <div>
                <strong>${influencerName}</strong>
              </div>
              <div>
                Total: ${val}<br/>${campaignStatsString}
              </div>
            </div>`;
          },
        },
      },
    }));
  }, [campaignData, selectedStat]);

  return (
    <Grid item xs container justifyContent="center" alignItems="center">
      <Grid item xs={11} container justifyContent="end">
        <Box sx={{ minWidth: 200 }}>
          <FormControl fullWidth>
            <InputLabel
              id="category-select-label"
              sx={{ color: "#FFF", fontSize: "1rem !important" }}
            >
              Select Category
            </InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              label="Category"
              variant="standard"
              sx={{
                color: "#FFF",
                background: "#FFA3C7",
                borderRadius: 1,
                padding: 1,
              }}
              onChange={handleCategoryChange}
              value={selectedCategory}
            >
              {categoryList.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={11} container justifyContent="end">
        <Box sx={{ minWidth: 200 }}>
          <FormControl fullWidth>
            <InputLabel
              id="stat-select-label"
              sx={{ color: "#FFF", fontSize: "1rem !important" }}
            >
              Select Stat
            </InputLabel>
            <Select
              labelId="stat-select-label"
              id="stat-select"
              label="Stat"
              variant="standard"
              sx={{
                color: "#FFF",
                background: "#FFA3C7",
                borderRadius: 1,
                padding: 1,
              }}
              onChange={handleStatChange}
              value={selectedStat}
            >
              <MenuItem value="instaFollowers">Instagram Followers</MenuItem>
              <MenuItem value="instaLikes">Instagram Likes</MenuItem>
              <MenuItem value="instaImpressions">
                Instagram Impressions
              </MenuItem>
              <MenuItem value="instaReach">Instagram Reach</MenuItem>
              <MenuItem value="instaSaves">Instagram Saves</MenuItem>
              <MenuItem value="instaShares">Instagram Shares</MenuItem>
              <MenuItem value="instaStoryViews">Instagram Story Views</MenuItem>
              <MenuItem value="tiktokComments">TikTok Comments</MenuItem>
              <MenuItem value="tiktokLikes">TikTok Likes</MenuItem>
              <MenuItem value="tiktokPlays">TikTok Plays</MenuItem>
              <MenuItem value="tiktokSaves">TikTok Saves</MenuItem>
              <MenuItem value="tiktokShares">TikTok Shares</MenuItem>
              <MenuItem value="tiktokViews">TikTok Views</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Chart
        options={updatedOptions}
        series={series}
        type="donut"
        height={height / 1.4}
        width={width - 50}
      />
    </Grid>
  );
};

export default InfluencerPerformanceChart;
