import { gql } from "@apollo/client";

const GET_STARTED_CAMPAIGNS = gql`
  query getStartedCampaigns($campaignId: ID!) {
    getStartedCampaigns(campaignId: $campaignId) {
      id
      name
      coverPic
      media
      campaignType
      status
      contract
      cost
      costType
      description
      includesStories
      includesReels
      includesVideos
      includesPictures
      requiresUsageRights
      usageRightsPeriod
      started
      influencersCount
      brandName
      approvedContracts {
        id
        userId
        influencerQuote
        influencerQuoteType
        brandQuote
        contractType
        description
        finalFee
        platformFee
        influencerName
        influencerImage
      }
    }
  }
`;

export default GET_STARTED_CAMPAIGNS;
