import { Button } from "@mui/material";
import { useMutation } from "@apollo/client";

import DELETE_USER from "../../graphql/mutations/deleteUser";
import RESTORE_USER from "../../graphql/mutations/restoreUser";

const DeleteRestoreButton = ({user, refetchQueries}: any) =>{
  const [deleteUser] = useMutation(DELETE_USER, {
    refetchQueries: refetchQueries,
  });

  const [restoreUser] = useMutation(RESTORE_USER, {
    refetchQueries: refetchQueries,
  });

  return(
    <Button
      variant="contained"
      color="warning"
      onClick={() =>

        user.deletedAt ? restoreUser({
          variables: {
            userId: user.id,
          },
        }) :
        deleteUser({
          variables: {
            userId: user.id,
          },
        })
      }
    >
      { user.deletedAt ? 'Restore' : 'Delete'}
    </Button>
  );
}

export default DeleteRestoreButton;