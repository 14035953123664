import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Survey from "../components/adminPanelComponents/Surveys";
import Categories from "../components/adminPanelComponents/Categories";

export default function AdminPanel() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div
      style={{
        height: "calc(100vh - 50px)",
        width: "100vw",
        backgroundColor: "whitesmoke",
        padding: 20,
      }}
    >
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>Survey</Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Create A new Survey
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Survey />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Categories
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Manage user niches
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Categories />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Misc Settings
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            More Settings to Come Soon
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>FUTURE ADMIN MISC SETTINGS TO BE ADDED HERE</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
