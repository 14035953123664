import { gql } from "@apollo/client";

const GET_INFLUENCER_INSIGHTS = gql`
  query influencerInsights($userId: ID!) {
    getMyTiktokStats(userId: $userId) {
      accountVisibility
      accountsEngaged
      femaleRatio
      following
      growthRate
      id
      link
      maleRatio
      topAgeRange
      topCity
      topCityPercentage
      topCountry
      topCountryPercentage
      videoViews
      commission
    }
    getMyInstagramStats(userId: $userId) {
      accountVisibility
      accountsEngaged
      accountsReached
      femaleRatio
      following
      growthRate
      id
      link
      maleRatio
      topAgeRange
      topCity
      topCityPercentage
      topCountry
      topCountryPercentage
    }
  }
`;

export default GET_INFLUENCER_INSIGHTS;
