import React, { ReactElement, FC, useState, Fragment } from "react";
import { Grid, TableContainer } from "@mui/material";
import { useQuery } from "@apollo/client";
import DASHBOARD_STATS from "../graphql/queries/getDashboardStats";
import StatCard from "../components/dashboardComponents/miniStatCard";
import CategoryPopulation from "../components/dashboardComponents/categoryPopulation";

const legend: any = {
  total_active_brands: "Active Brands",
  total_active_influencers: "Active Influencers",
  total_active_campaigns: "Active Campaigns",
  total_pending_campaigns: "Pending Campaigns",
  total_instagram_following: "Instagram Following",
  total_instagram_reach: "Instagram Reach",
  total_tiktok_following: "TikTok Following",
  total_tiktok_views: "TikTok Views",
};

const Dashboard: FC<any> = (): ReactElement => {
  const [dashboardStats, setDashboardStats] = useState<any>(null);

  useQuery(DASHBOARD_STATS, {
    onCompleted: (res) => {
      setDashboardStats(res.adminDashboardStats);
    },
  });
  if (dashboardStats === null) return <></>;
  return (
    <Grid
      item
      xs={12}
      sx={{
        width: "100vw",
        height: "calc(100vh - 64px)",
        backgroundColor: "whitesmoke",
        padding: 4,
        overflowY: "scroll",
      }}
      container
      justifyContent="space-around"
    >
      {Object.entries(dashboardStats).map(([key, value]: any) => {
        if (key === "influencers_in_niche" || key === "brands_in_niche") {
          return (
            <TableContainer
              sx={{ width: 500, borderTop: 1, marginTop: 3 }}
              key={`stat-card-${key}`}
            >
              <CategoryPopulation
                rows={value}
                title={key === "influencers_in_niche" ? "Influencer" : "Brand"}
              />
            </TableContainer>
          );
        } else {
          return (
            <Fragment key={`stat-card-${key}`}>
              <StatCard
                title={legend[key]}
                value={value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
            </Fragment>
          );
        }
      })}
    </Grid>
  );
};

export default Dashboard;
