import { gql } from "@apollo/client";

const UPDATE_USER_INFO = gql`
  mutation updateUserInfo(
    $userId: ID!
    $email: String
    $name: String
    $image: String
    $instagram: String
    $tiktok: String
    $facebook: String
    $youtube: String
    $website: String
    $country: String
    $city: String
    $theme: Int
    $categories: [ID!]
    $signature: String
    
  ) {
    updateUserInfo(
      userId: $userId
      email: $email
      name: $name
      image: $image
      instagram: $instagram
      tiktok: $tiktok
      facebook: $facebook
      youtube: $youtube
      website: $website
      country: $country
      city: $city
      theme: $theme
      categories: $categories
      signature: $signature
      
    )
  }
`;

export default UPDATE_USER_INFO;
