import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";

function ChartWrapper({ userRole, chart, imageSrc, altText, component }) {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return userRole === "Admin" || chart?.published === true ? (
    component
  ) : (
    <>
      <div
        style={{
          position: "absolute",
          top: "80%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Button variant="contained" color="primary" onClick={openModal}>
          Click For More Info
        </Button>
      </div>
      <Modal open={isModalOpen} onClose={closeModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height: 400,
            backgroundColor: "white",
            padding: 20,
            borderRadius: 5,
          }}
        >
          <h3>Coming Soon</h3>
          <h4>Results will be populated within 30 days.</h4>
          <img
            src={imageSrc}
            alt={altText}
            style={{ objectFit: "contain", width: "100%", marginTop: "5px" }}
          />
          <Button variant="contained" color="secondary" onClick={closeModal}>
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default ChartWrapper;
