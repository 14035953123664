import { gql } from "@apollo/client";

const GET_RESPONDER_LIST = gql`
  query adminGetSurveyAnswerDetails($surveyId: ID!, $answer: String!) {
    adminGetSurveyAnswerDetails(surveyId: $surveyId, answer: $answer) {
      id
      user {
        id
        image
        name
      }
    }
  }
`;

export default GET_RESPONDER_LIST;
