import { FC } from "react";
import Dashboard from "./pages/dashboard";
import AdminPanel from "./pages/adminPanel";
import CampaignListing from "./pages/campaignsListing";
import InfluencersListing from "./pages/InfluencersListing";
import BrandsListing from "./pages/brandsListing";
import MetricsGraphs from "./pages/metricsGraphs";
import NotificationListing from "./pages/notifications";
import NotificationsData from "./pages/notificationsData";

// interface
interface Route {
  key: string;
  title: string;
  path: string;
  enabled: boolean;
  component: FC<{}>;
}

export const routes: Array<Route> = [
  {
    key: "dashboard-route",
    title: "Dashboard",
    path: "/",
    enabled: true,
    component: Dashboard,
  },
  {
    key: "admin-panel-route",
    title: "Admin Panel",
    path: "/admin-panel",
    enabled: true,
    component: AdminPanel,
  },
  {
    key: "campaigns-listing-route",
    title: "Campaign Listing",
    path: "/campaigns",
    enabled: true,
    component: CampaignListing,
  },
  {
    key: "influencers-listing-route",
    title: "Influencer Listing",
    path: "/influencers",
    enabled: true,
    component: InfluencersListing,
  },
  {
    key: "brands-listing-route",
    title: "Brand Listing",
    path: "/brands",
    enabled: true,
    component: BrandsListing,
  },
  {
    key: "metrics-graphs-route",
    title: "Metrics Graphs",
    path: "/graphs",
    enabled: true,
    component: MetricsGraphs,
  },
  {
    key: "notifications-route",
    title: "Notifications",
    path: "/notifications",
    enabled: true,
    component: NotificationListing,
  },
  {
    key: "notifications-data-route",
    title: "Notifications Data",
    path: "/notifications-data",
    enabled: true,
    component: NotificationsData,
  },
];
