import { gql } from "@apollo/client";

const GET_ALL_NOTIFICATIONS = gql`
  query getAllNotifications {
    getAllNotifications {
      id
      userName
      userId
      read
      notificationType
      createdAt
      text
    }
  }
`;

export default GET_ALL_NOTIFICATIONS;
