import { useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  styled,
  TableCell,
  tableCellClasses,
  TableBody,
} from "@mui/material";
import GET_INFLUENCERS_WISHLIST from "../../graphql/queries/getInfluencersWishlist"
import GET_BRAND_WISHLIST_COUNT from "../../graphql/queries/getBrandWishlistCount"
import TickIcon from "../..//assets/tick.svg"
import CrossIcon from "../..//assets/cross.svg"

type WishlistItemType = {
  id: string;
  brandId: number;
  influencerId: string;
  collaborated: string;
  brandName: string
  brand: {
    name: string;
    email: string;
    brandName: string;
  };
};

type WishlistType = {
  id: string;
};

type BrandCountType = {
  brandId: number;
  count: number;
  brandName: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const InfluencerWishlist = ({ id }: WishlistType) => {
  const [brandIds, setBrandIds] = useState([])
  const [brandNames, setBrandNames] = useState([])
  const [brandCount, setBrandCount] = useState([])

  const { data } = useQuery(GET_INFLUENCERS_WISHLIST, {
    variables: { influencerId: id },
    onCompleted: (res) => {
      setBrandIds(res.getInfluencerWishlist.map((item: WishlistItemType) => item?.brandId));
      setBrandNames(res.getInfluencerWishlist.map((item: WishlistItemType) => item?.brandName));
    },
  });

  const { refetch: refetchCount } = useQuery(GET_BRAND_WISHLIST_COUNT, {
    variables: { brandNames: brandNames },
    onCompleted: (res) => {
      setBrandCount(res.getBrandWishlistCount);
    }
  })

  useEffect(() => {
    if (brandIds || brandNames) {
      refetchCount()
    }
  }, [brandIds, brandNames, refetchCount])

  function getCountForBrandId(brandId: number) {
    const brandCountItem: BrandCountType | undefined = (brandCount as BrandCountType[])?.find((item: BrandCountType) => item?.brandId === brandId);

    return brandCountItem ? brandCountItem?.count : 0; // Return 0 if not found
  }

  function getCountForBrandName(brandName: string) {
    const brandCountItem: BrandCountType | undefined = (brandCount as BrandCountType[])?.find((item: BrandCountType) => item?.brandName === brandName);

    return brandCountItem ? brandCountItem?.count : 0; // Return 0 if not found
  }

  if (!data) return <></>;
  return (
    <Grid
      item
      xs={12}
      sx={{ minWidth: "70vw" }}
      container
      justifyContent="space-around"
    >
      <Typography variant="h4" align="center" sx={{ marginBottom: 5 }}>
        Influencers Wishlist
      </Typography>
      {data && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 1200 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ width: 50 }}>#</StyledTableCell>
                {/* <StyledTableCell align="left" sx={{ width: 300 }}>
                  Name
                </StyledTableCell> */}
                <StyledTableCell align="left">Brand Name</StyledTableCell>
                {/* <StyledTableCell align="left">Email</StyledTableCell> */}
                <StyledTableCell align="left">Collaborated</StyledTableCell>
                <StyledTableCell align="left">Added To Count</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.getInfluencerWishlist?.map(
                (row: WishlistItemType, index: number) => (
                  <StyledTableRow key={row?.id + "-" + index}>
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    {/* <StyledTableCell align="left">{row?.brand?.name}</StyledTableCell> */}
                    <StyledTableCell align="left">{row?.brand?.brandName || row?.brandName}</StyledTableCell>
                    {/* <StyledTableCell align="left">
                      {row?.brand?.email}
                    </StyledTableCell> */}
                    <StyledTableCell align="left">
                      {row?.collaborated ? <img src={TickIcon} alt="Tick Icon" /> : <img src={CrossIcon} alt="Cross Icon" />}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{ width: 200, wordBreak: "break-word" }}
                    >
                     {row?.brandName ? getCountForBrandName(row?.brandName) : getCountForBrandId(row?.brandId) }
                    </StyledTableCell>
                  </StyledTableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
};

export default InfluencerWishlist;
