import { useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Modal,
  Typography,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CHANGE_BRAND from "../../graphql/mutations/updateBrand";

type DetailsModalType = {
  open: boolean;
  setOpen: any;
  brand: any;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DetailsModal = ({ open, setOpen, brand }: DetailsModalType) => {
  const [commission, setCommission] = useState(brand?.agencyCommission || "");

  const [updateBrand] = useMutation(CHANGE_BRAND, {
    refetchQueries: ["adminGetBrands"],
  });

  useEffect(() => {
    if (open && brand?.agencyCommission) {
      setCommission(brand.agencyCommission);
    }
  }, [open, brand]);

  const update = () => {
    updateBrand({
      variables: {
        brandId: brand.id,
        agencyCommission: commission,
      },
    });
    setOpen(false);
  };

  const handleChange = (event: any) => {
    setCommission(event.target.value);
  };

  const options = [];
  for (let i = 5; i <= 100; i += 5) {
    options.push(
      <MenuItem key={i} value={i}>
        {i} %
      </MenuItem>
    );
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          align="center"
        >
          Update Brand
        </Typography>

        <FormControl fullWidth>
          <InputLabel id="number-select-label">Select Agency Commission</InputLabel>
          <Select
            labelId="number-select-label"
            id="number-select"
            value={commission}
            label="Choose a number"
            onChange={handleChange}
          >
            {options}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          onClick={() => update()}
          sx={{ height: 45, marginTop: 2 }}
        >
          Update
        </Button>
      </Box>
    </Modal>
  );
};

export default DetailsModal;
