import { ReactElement, FC, useState } from "react";
import {
    Typography,
    Grid,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import GET_ALL_NOTIFICATIONS from "../graphql/queries/getAllNotifications";





const NotificationListing: FC<any> = (): ReactElement => {

    const [notifications, setNotifications] = useState<any[]>([]);  // Initialize as empty array
    useQuery(GET_ALL_NOTIFICATIONS, {
        onCompleted: (res) => {
            console.log("🚀 ~ res:", res)
            setNotifications(res.getAllNotifications);
        },
    });

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 70, type: 'number' },
        { field: 'userId', headerName: 'User ID', type: 'number', width: 130 },
        { field: 'userName', headerName: 'Name', width: 130 },
        // { field: 'user_type', headerName: 'User Type', width: 130, type: 'string', valueGetter: (value, row) => row.notificationType == 1 ? "Brand" : "Influencer" },
        { field: 'text', headerName: 'Text', width: 600 },
        { field: 'read', headerName: 'Read', width: 130, type: 'boolean' },
        // {
        //   field: 'fullName',
        //   headerName: 'Full name',
        //   description: 'This column has a value getter and is not sortable.',
        //   sortable: false,
        //   width: 160,
        //   valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
        // },
    ];

    // const rows = [
    //   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
    //   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
    //   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    //   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
    //   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    //   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
    //   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    //   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    //   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    // ];

    return (
        <Grid
            item
            xs={12}
            sx={{
                width: "100vw",
                height: "calc(100vh)",
                backgroundColor: "whitesmoke",
                padding: 5,
            }}
        >
            <Typography variant="h4" align="center" sx={{ marginBottom: 5 }}>
                Notification Listing
            </Typography>
            <div style={{ height: '80%', width: '100%' }}>
                <DataGrid
                    rows={notifications}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                    checkboxSelection={false}
                />
            </div>
        </Grid>
    );
};

export default NotificationListing;
