import React, {ReactElement, FC, useState, useEffect, useMemo} from "react";
import {Typography, Grid, Box, FormControl, Select, MenuItem, SelectChangeEvent} from "@mui/material";
import {useQuery, useLazyQuery} from "@apollo/client";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import GET_BRANDS from "../graphql/queries/getBrands";
import {GET_BRAND_CAMPAIGN_NAMES} from "../graphql/queries/getBrandCampaignNames";
import GET_ADMIN_INFLUENCERS_FOR_CAMPAIGN from "../graphql/queries/getAdminInfluencersForCampaign";
import GET_STARTED_CAMPAIGNS from "../graphql/queries/getStartedCampaigns";
import GET_INVOICED_CAMPAIGNS from "../graphql/queries/getInvoicedCampaigns";
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import GET_PRE_INVOICE from "../graphql/queries/getPreInvoice";

interface Campaign {
    id: string;
    name: string;
    createdAt: Date;
    user: any;
    campaignType: string;
    category: any;
    followerPreference: string[];
    media: string;
    status: string;
}

interface Brand {
    id: string;
    brandName: string;
    image: string;
    email: string;
    country: string;
    city: string;
    accountStatus: string;
    categories: CategoryType[];
    lastSignInAt: string;
    currentSignInAt: string;
    deletedAt: string;
    createdAt: string;
}

interface CategoryType {
    id: string;
    name: string;
}

interface BrandSelectorProps {
    brands: Brand[];
    selectedBrand: string;
    handleBrandChange: (event: SelectChangeEvent) => void;
}

const BrandSelector: FC<BrandSelectorProps> = ({brands, selectedBrand, handleBrandChange}) => (
    <FormControl sx={{width: "200px", marginBottom: 5, marginRight: 2}}>
        <Select
            labelId="brand-select-label"
            value={selectedBrand || "select_brand"}
            onChange={handleBrandChange}
            fullWidth
            sx={{marginBottom: 2}}
        >
            <MenuItem value="select_brand">
                <em>Select Brand</em>
            </MenuItem>
            {brands.map((brand) => (
                <MenuItem key={brand.id} value={brand.id}>
                    {brand.brandName}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

interface CampaignSelectorProps {
    campaigns: Campaign[];
    selectedCampaign: string;
    handleCampaignChange: (event: SelectChangeEvent) => void;
}

const CampaignSelector: FC<CampaignSelectorProps> = ({campaigns, selectedCampaign, handleCampaignChange}) => (
    <FormControl sx={{width: "200px", marginBottom: 5}}>
        <Select
            labelId="campaign-select-label"
            value={selectedCampaign || "select_campaign"}
            onChange={handleCampaignChange}
            fullWidth
            sx={{marginBottom: 2}}
            disabled={campaigns.length === 0}
        >
            <MenuItem value="select_campaign">
                <em>Select Campaign</em>
            </MenuItem>
            {campaigns.map((campaign) => (
                <MenuItem key={campaign.id} value={campaign.id}>
                    {campaign.name}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

const NotificationsData: FC<any> = (): ReactElement => {
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [brands, setBrands] = useState<Brand[]>([]);
    const [selectedCampaign, setSelectedCampaign] = useState<string>("");
    const [selectedBrand, setSelectedBrand] = useState<string>("");
    const [adminInfluencers, setAdminInfluencers] = useState<any[]>([]);
    const [startedCampaigns, setStartedCampaigns] = useState<any[]>([]);
    const [invoicedCampaigns, setInvoicedCampaigns] = useState<any[]>([]);
    const [approvedInfluencers, setApprovedInfluencers] = useState([]);
    const [campaignTotal, setCampaignTotal] = useState<number>(0);
    const [agencyCommission, setAgencyCommission] = useState<number>(0);
    const [vat, setVat] = useState("");
    const [totalCommission, setTotalCommission] = useState<number>(0);
    const [totalInfluencerFee, setTotalInfluencerFee] = useState<number>(0);

    // const [totalCurrencyType, setTotalCurrencyType] = useState();

    const {data: adminInfluencersData} = useQuery(GET_ADMIN_INFLUENCERS_FOR_CAMPAIGN, {
        variables: {campaignId: selectedCampaign},
        skip: !selectedCampaign,
    });

    useEffect(() => {
        if (adminInfluencersData) {
            setAdminInfluencers(adminInfluencersData.getAdminInfluencersForCampaign);
        }
    }, [adminInfluencersData]);

    useQuery(GET_BRANDS, {
        onCompleted: (res) => {
            setBrands(res.adminGetBrands);
        },
    });

    useQuery(GET_STARTED_CAMPAIGNS, {
        variables: {campaignId: selectedCampaign},
        onCompleted: (res) => {
            setStartedCampaigns(res.getStartedCampaigns);
        },
    });

    useQuery(GET_INVOICED_CAMPAIGNS, {
        variables: {campaignId: selectedCampaign},
        onCompleted: (res) => {
            setInvoicedCampaigns(res.getInvoicedCampaigns);
        },
    });

    useQuery(GET_PRE_INVOICE, {
        variables: {
            userId: localStorage.getItem("currentUser"),
            campaignId: selectedCampaign,
        },
        onCompleted: (res) => {
            setApprovedInfluencers(res.getPreInvoice);
            let total = 0;
            let commission = 0;
            let influencerFee = 0;
            setAgencyCommission(res.getPreInvoice[0].agencyCommission)
            res.getPreInvoice.forEach((invoice:any) => {
                //   setTotalCurrencyType(getCurrencySymbol(invoice.finalFeeType))
                total += invoice.finalFee;
                commission += (invoice.finalFee * res.getPreInvoice[0].agencyCommission) / 100;
                influencerFee = influencerFee + (invoice.finalFee - (invoice.finalFee * res.getPreInvoice[0].agencyCommission) / 100);
            });
            setCampaignTotal(total);
            setTotalCommission(commission);
            setTotalInfluencerFee(influencerFee);
            setVat(res.getPreInvoice[0].campaign.vat);
        },
    });

    const [getBrandCampaigns, {data: brandCampaignData}] = useLazyQuery(GET_BRAND_CAMPAIGN_NAMES);

    const handleCampaignChange = (event: SelectChangeEvent) => {
        setSelectedCampaign(event.target.value as string);
    };

    const handleBrandChange = (event: SelectChangeEvent) => {
        const brandId = event.target.value as string;
        setSelectedBrand(brandId);
        getBrandCampaigns({variables: {brandId}});
    };

    useEffect(() => {
        if (brandCampaignData) {
            setCampaigns(brandCampaignData.getBrandCampaigns.length === 0 ? [] : brandCampaignData.getBrandCampaigns);
        }
    }, [brandCampaignData]);

    const contentColumns: GridColDef[] = [
        {field: "id", headerName: "Task ID", width: 130, type: "number"},
        {field: "influencerId", headerName: "Influencer ID", type: "number", width: 130},
        {field: "name", headerName: "Influencer Name", width: 130},
        {field: "taskDescription", headerName: "Task Description", width: 130},
        {field: "contentMedia", headerName: "Content Media", width: 130},
        {field: "contentStatus", headerName: "Content Type", width: 130},
        {
            field: 'pending',
            headerName: 'Pending Status',
            width: 130,
            renderCell: (params) => (
                <div>
                    {params.row.pending ? (
                        <CheckCircleIcon style={{color: 'green', fontSize: 24}}/>
                    ) : (
                        <CancelIcon style={{color: 'red', fontSize: 24}}/>
                    )}
                </div>
            ),
        },
        {
            field: 'submitted',
            headerName: 'Submit Status',
            width: 130,
            renderCell: (params) => (
                <div>
                    {params.row.submitted ? (
                        <CheckCircleIcon style={{color: 'green', fontSize: 24}}/>
                    ) : (
                        <CancelIcon style={{color: 'red', fontSize: 24}}/>
                    )}
                </div>
            ),
        },
        {
            field: 'rejected',
            headerName: 'Reject Status',
            width: 130,
            renderCell: (params) => (
                <div>
                    {params.row.rejected ? (
                        <CheckCircleIcon style={{color: 'green', fontSize: 24}}/>
                    ) : (
                        <CancelIcon style={{color: 'red', fontSize: 24}}/>
                    )}
                </div>
            ),
        },
        {
            field: 'accepted',
            headerName: 'Accept Status',
            width: 130,
            renderCell: (params) => (
                <div>
                    {params.row.accepted ? (
                        <CheckCircleIcon style={{color: 'green', fontSize: 24}}/>
                    ) : (
                        <CancelIcon style={{color: 'red', fontSize: 24}}/>
                    )}
                </div>
            ),
        },
    ];

    const campaignResultsColumns: GridColDef[] = [
        {field: "id", headerName: "Task ID", width: 130, type: "number"},
        {field: "influencerId", headerName: "Influencer ID", type: "number", width: 130},
        {field: "name", headerName: "Influencer Name", width: 130},
        {field: "taskDescription", headerName: "Task Description", width: 130},
        {field: "contentMedia", headerName: "Content Media", width: 130},
        {field: "contentStatus", headerName: "Content Type", width: 130},
        {
            field: 'campaignResult',
            headerName: 'Submit Status',
            width: 130,
            renderCell: (params) => (
                <div>
                    {params.row.campaignResult ? (
                        <CheckCircleIcon style={{color: 'green', fontSize: 24}}/>
                    ) : (
                        <CancelIcon style={{color: 'red', fontSize: 24}}/>
                    )}
                </div>
            ),
        },
    ];

    const startedCampaignColumns: GridColDef[] = [
        {field: 'id', headerName: 'ID', width: 70, type: 'number'},
        {field: 'name', headerName: 'Name', width: 130},
        {field: 'brandName', headerName: 'Brand Name', width: 130},
        {
            field:'status ',
            headerName: 'Status',
            width: 130,
            renderCell: (params) => <div>
                {params.value !== "Cancelled" ? (
                    <CheckCircleIcon style={{color: 'green', fontSize: 24}}/>
                ) : (
                    <CancelIcon style={{color: 'red', fontSize: 24}}/>
                )}
            </div>
        },
        {field: 'influencersCount', headerName: 'Inlfuencers Count', width: 130, type: 'number'},
        {field: 'status', headerName: 'Status', width: 130},
    ];

    const negotationColumns: GridColDef[] = [
        {field: 'id', headerName: 'ID', type: 'number', width: 100},
        {field: 'name', headerName: 'Name', width: 130},
        {field: 'brandName', headerName: 'Brand Name', width: 130},
        {field: 'influencersCount', headerName: 'Inlfuencers Count', type: 'number', minWidth: 220},
        {
            field: 'cost',
            headerName: 'Final fee',
            width: 120,
            valueGetter: (value, row) => `${row.costType} ${row.cost}`,
            renderCell: (params) => <Chip
                label={params.value}
                sx={{
                    backgroundColor: 'pink',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '1rem'
                }}
            />
        },
        {
            field:'status ',
            headerName: 'Status',
            width: 130,
            renderCell: (params) => <div>
                {params.value !== "Cancelled" ? (
                    <CheckCircleIcon style={{color: 'green', fontSize: 24}}/>
                ) : (
                    <CancelIcon style={{color: 'red', fontSize: 24}}/>
                )}
            </div>
        },
        {
            field: 'approvedContracts',
            headerName: 'List of Influencers (Name/Price)',
            width: 300,
            renderCell: (params) => (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        marginTop: 1,
                        minHeight: 'auto',
                        maxHeight: '100%',
                        overflow: 'auto',
                        padding: 1
                    }}
                >
                    {params.value.map((item: any, index: any) => (
                        item.influencerQuote ? (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: 2,
                                    padding: '8px',
                                    minHeight: 90,
                                }}
                            >
                                <img
                                    src={item.influencerImage}
                                    alt="Influencer Image"
                                    style={{width: 70, height: 70, objectFit: 'cover'}}
                                />
                                <Chip
                                    label={`${item.influencerName}/    ${item.influencerQuoteType ? item.influencerQuoteType : 'USD'} ${item.influencerQuote}`}
                                    sx={{
                                        maxWidth: 'calc(100% - 70px)',
                                        backgroundColor: 'pink',
                                        color: 'white',
                                        fontWeight: 'bold',
                                        fontSize: '1rem'
                                    }}
                                />
                                <Chip
                                    label={`brand price: ${item.brandQuote}`}
                                    sx={{
                                        maxWidth: 'calc(100% - 70px)',
                                        backgroundColor: 'pink',
                                        color: 'white',
                                        fontWeight: 'bold',
                                        fontSize: '1rem'
                                    }}
                                />
                            </Box>
                        ) : null
                    ))}
                </Box>
            ),
        }
    ];

    const invoiceColumns: GridColDef[] = [
        {field: 'id', headerName: 'ID', type: 'number', width: 100},
        {field: 'name', headerName: 'Name', width: 130},
        {field: 'brandName', headerName: 'Brand Name', width: 130},
        {field: 'influencersCount', headerName: 'Inlfuencers Count', type: 'number', minWidth: 220},
        // {
        //     field: 'campaignContracts',
        //     headerName: 'Price',
        //     width: 120,
        //     renderCell: (params) => <Chip
        //         label={params.value.finalFee}
        //         sx={{
        //             backgroundColor: 'pink',
        //             color: 'white',
        //             fontWeight: 'bold',
        //             fontSize: '1rem'
        //         }}
        //     />
        // },
        {
            field: 'status',
            headerName: 'Status',
            width: 130,
            renderCell: (params) => <div>
                {params.value !== "Cancelled" ? (
                    <CheckCircleIcon style={{color: 'green', fontSize: 24}}/>
                ) : (
                    <CancelIcon style={{color: 'red', fontSize: 24}}/>
                )}
            </div>
        },
        {
            field: 'cost',
            headerName: 'Final fee',
            width: 120,
            valueGetter: (value, row) => `${row.costType} ${campaignTotal}`,
            renderCell: (params) => <Chip
                label={params.value}
                sx={{
                    backgroundColor: 'pink',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '1rem'
                }}
            />
        },
        {
            field: 'campaignContracts',
            headerName: 'List of Influencers (Name/Price)',
            width: 220,
            renderCell: (params) => (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        marginTop: 2,
                        minHeight: 'auto',
                        maxHeight: '100%',
                        overflow: 'auto',
                        padding: 1
                    }}
                >
                    {params.value.map((item: any, index: any) => (
                        item.influencerQuote ? (

                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: 1
                                }}
                            >
                                <img
                                    src={item.influencerImage}
                                    alt="Influencer Image"
                                    style={{width: 70, height: 70, objectFit: 'cover'}}
                                />
                                <Chip
                                    label={`${item.influencerName}/    ${item.influencerQuoteType ? item.influencerQuoteType : 'USD'} ${item.influencerQuote}`}
                                    sx={{
                                        maxWidth: 'calc(100% - 60px)',
                                        backgroundColor: 'pink',
                                        color: 'white',
                                        fontWeight: 'bold',
                                        fontSize: '1rem'
                                    }}
                                />
                                <Chip
                                    label={`Final Price: ${item.finalFee}`}
                                    sx={{
                                        maxWidth: 'calc(100% - 70px)',
                                        backgroundColor: 'pink',
                                        color: 'white',
                                        fontWeight: 'bold',
                                        fontSize: '1rem'
                                    }}
                                />
                                <Chip
                                    label={`Agency Fee: ${totalCommission}`}
                                    sx={{
                                        maxWidth: 'calc(100% - 70px)',
                                        backgroundColor: 'pink',
                                        color: 'white',
                                        fontWeight: 'bold',
                                        fontSize: '1rem'
                                    }}
                                />
                                <Chip
                                    label={`Influencer Fee: ${totalInfluencerFee}`}
                                    sx={{
                                        maxWidth: 'calc(100% - 70px)',
                                        backgroundColor: 'pink',
                                        color: 'white',
                                        fontWeight: 'bold',
                                        fontSize: '1rem'
                                    }}
                                />
                            </Box>
                        ) : null
                    ))}
                </Box>
            ),
        },
    ];

    const totalApproved = adminInfluencers.filter(influencer => influencer.accepted).length;
    const totalPending = adminInfluencers.filter(influencer => influencer.pending).length;
    const totalRejected = adminInfluencers.filter(influencer => influencer.rejected).length;
    const totalSubmitted = adminInfluencers.filter(influencer => influencer.submitted).length;

    const remainingContentCountInfluencer = adminInfluencers.length > 0 ? adminInfluencers[0].remainingContent : 0;
    const remainingInfluencersCount = adminInfluencers.length > 0 ? adminInfluencers[0].remainingInfluencers : 0;

    const remainingCampaignResults = adminInfluencers.filter(influencer => !influencer.campaignResult).length;
    const remainingResultsInfluencersCount = new Set(
        adminInfluencers
            .filter(influencer => !influencer.campaignResult)
            .map(influencer => influencer.influencerId)
    ).size;

    const contentStatusCounts = useMemo(() => {
        return adminInfluencers.reduce(
            (acc, influencer) => {
                if (influencer.contentStatus === "Story") acc.totalStories++;
                if (influencer.contentStatus === "Post") acc.totalPosts++;
                if (influencer.contentStatus === "Reel" && influencer.contentMedia === "Instagram") acc.totalReels++;
                if (influencer.contentMedia === "Tiktok") acc.totalTiktoks++;
                return acc;
            },
            {totalStories: 0, totalPosts: 0, totalReels: 0, totalTiktoks: 0}
        );
    }, [adminInfluencers]);

    const {totalStories, totalPosts, totalReels, totalTiktoks} = contentStatusCounts;

    return (
        <Grid container spacing={2} sx={{height: '100vh'}}>
            {/* Main Content */}
            <Grid
                item
                xs={12}
                sx={{
                    width: "100vw",
                    height: "calc(100vh - 60px)", // Adjust to fit your header/footer if any
                    backgroundColor: "whitesmoke",
                    padding: 5,
                    overflow: "auto",
                }}
            >
                <BrandSelector brands={brands} selectedBrand={selectedBrand} handleBrandChange={handleBrandChange}/>
                <CampaignSelector campaigns={campaigns} selectedCampaign={selectedCampaign}
                                  handleCampaignChange={handleCampaignChange}/>
                <div style={{height: "60%", width: "100%"}}>
                    {selectedCampaign ? (
                        <>
                            <Typography variant="h4" align="center" sx={{marginBottom: 5}}>
                                Influencer Content
                            </Typography>
                            <Box
                                sx={{
                                    padding: 2,
                                    backgroundColor: "lightblue",
                                    borderRadius: 2,
                                    boxShadow: 3,
                                    marginBottom: 2,
                                }}
                            >
                                <Typography variant="h5" align="center">
                                    <span style={{fontWeight: "bold"}}>Total Pending:</span> {totalPending} | <span
                                    style={{fontWeight: "bold"}}>Total Submissions:</span> {totalSubmitted} | <span
                                    style={{fontWeight: "bold"}}>Total Rejections:</span> {totalRejected} | <span
                                    style={{fontWeight: "bold"}}>Total Approved:</span> {totalApproved} | <span
                                    style={{fontWeight: "bold"}}>Remaining Influencers:</span> {remainingInfluencersCount} | <span
                                    style={{fontWeight: "bold"}}>Remaining Content:</span> {remainingContentCountInfluencer}
                                </Typography>
                            </Box>
                            <DataGrid
                                rows={adminInfluencers}
                                columns={contentColumns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {page: 0, pageSize: 10},
                                    },
                                }}
                                pageSizeOptions={[5, 10, 20, 30]}
                                checkboxSelection={false}
                                sx={{
                                    height: 'calc(60vh - 60px)', // Adjust if needed
                                }}
                            />
                            {adminInfluencers.length > 0 && (
                                <>
                                    <Typography variant="h4" align="center" sx={{marginBottom: 5, marginTop: 5}}>
                                        Influencer Campaign Results
                                    </Typography>
                                    <Box
                                        sx={{
                                            padding: 2,
                                            backgroundColor: "lightblue",
                                            borderRadius: 2,
                                            boxShadow: 3,
                                            marginBottom: 2,
                                        }}
                                    >
                                        <Typography variant="h5" align="center">
                                            <span
                                                style={{fontWeight: "bold"}}>Total Stories:</span> {totalStories} | <span
                                            style={{fontWeight: "bold"}}>Total Posts:</span> {totalPosts} | <span
                                            style={{fontWeight: "bold"}}>Total Instagram Reels:</span> {totalReels} | <span
                                            style={{fontWeight: "bold"}}>Total TikToks:</span> {totalTiktoks} | <span
                                            style={{fontWeight: "bold"}}>Remaining Influencers:</span> {remainingResultsInfluencersCount} | <span
                                            style={{fontWeight: "bold"}}>Remaining Campaign Results:</span> {remainingCampaignResults}
                                        </Typography>
                                    </Box>
                                    <DataGrid
                                        rows={adminInfluencers}
                                        columns={campaignResultsColumns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {page: 0, pageSize: 10},
                                            },
                                        }}
                                        pageSizeOptions={[5, 10, 20, 30]}
                                        checkboxSelection={false}
                                        sx={{
                                            height: 'calc(60vh - 60px)', // Adjust if needed
                                        }}
                                    />
                                </>
                            )}
                        </>
                    ) : (
                        <Grid container direction="column" justifyContent="center" alignItems="center"
                              sx={{height: '100%'}}>
                            <Typography variant="h4" align="center" sx={{fontWeight: "bold"}}>
                                {selectedBrand === ""
                                    ? "PLEASE SELECT A BRAND AND CAMPAIGN"
                                    : campaigns.length === 0
                                        ? "NO CAMPAIGNS ASSOCIATED WITH THIS BRAND"
                                        : "PLEASE SELECT A CAMPAIGN"}
                            </Typography>
                        </Grid>
                    )}
                </div>
            </Grid>

            {/* Grid Layout for DataGrids */}
            <Grid container spacing={2}>
                {/* Campaigns */}
                <Grid
                    item
                    xs={12} sm={4}
                    sx={{
                        height: 'calc(100vh - 60px)', // Adjust based on your layout needs
                        backgroundColor: 'whitesmoke',
                        padding: 2,
                    }}
                >
                    <Typography variant="h4" align="center" sx={{marginBottom: 3}}>
                        Campaigns
                    </Typography>
                    <div style={{height: '100%', width: '100%'}}>
                        <DataGrid
                            rows={startedCampaigns}
                            columns={startedCampaignColumns}
                            initialState={{
                                pagination: {
                                    paginationModel: {page: 0, pageSize: 10},
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            checkboxSelection={false}
                        />
                    </div>
                </Grid>

                {/* Negotation Brand */}
                <Grid
                    item
                    xs={12} sm={4}
                    sx={{
                        height: 'calc(100vh - 60px)', // Adjust based on your layout needs
                        backgroundColor: 'whitesmoke',
                        padding: 2,
                    }}
                >
                    <Typography variant="h4" align="center" sx={{marginBottom: 3}}>
                        Negotation Brand
                    </Typography>
                    <div style={{height: '100%', width: '100%'}}>
                        <DataGrid
                            rows={startedCampaigns}
                            columns={negotationColumns}
                            rowHeight={100}
                            initialState={{
                                pagination: {
                                    paginationModel: {page: 0, pageSize: 10},
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            checkboxSelection={false}
                        />
                    </div>
                </Grid>

                {/* Invoice */}
                <Grid
                    item
                    xs={12} sm={4}
                    sx={{
                        height: 'calc(100vh - 60px)', // Adjust based on your layout needs
                        backgroundColor: 'whitesmoke',
                        padding: 2,
                    }}
                >
                    <Typography variant="h4" align="center" sx={{marginBottom: 3}}>
                        Invoice
                    </Typography>
                    <div style={{height: '100%', width: '100%'}}>
                        <DataGrid
                            rows={invoicedCampaigns}
                            columns={invoiceColumns}
                            rowHeight={100}
                            initialState={{
                                pagination: {
                                    paginationModel: {page: 0, pageSize: 10},
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            checkboxSelection={false}
                        />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default NotificationsData;
