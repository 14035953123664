import { gql } from "@apollo/client";

const GET_INFLUENCER_FOR_GRAPH = gql`
  query getInfluencersForCampaigns($campaignIds: [ID!]) {
    getInfluencersForCampaigns(campaignIds: $campaignIds) {
      id
      name
      instagramStat {
        id
        accountVisibility
        accountsEngaged
        accountsReached
        following
        link
      }
      tiktokStat {
        id
        accountVisibility
        accountsEngaged
        following
        link
        videoViews
      }
      contractedCampaigns {
        id
        name
      }
    }
  }
`;

export default GET_INFLUENCER_FOR_GRAPH;
