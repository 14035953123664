import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

type listType = {
  id: string;
  brandName: string;
  name: string;
};
const MultiSelectDropdown = ({ selectFor, list, setSelectedIds }: any) => {
  const saveSelectedIds = (values: listType[]) => {
    const selectedValues = values.map((value) => value.id);
    setSelectedIds(selectedValues);
  };
  return (
    <Autocomplete
      multiple
      fullWidth
      id={`${selectFor}-multiselect`}
      options={list}
      onChange={(event, value) => saveSelectedIds(value)}
      getOptionLabel={(list: listType) => list.brandName || list.name}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label={selectFor} placeholder={selectFor} />
      )}
    />
  );
};

export default MultiSelectDropdown;
