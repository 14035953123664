import { gql } from "@apollo/client";

const LOGIN = gql`
  mutation userLogin($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      authenticatable {
        id
        userType
      }
      credentials {
        accessToken
        client
        uid
      }
    }
  }
`;

export default LOGIN;
