import { gql } from "@apollo/client";

const CREATE_SURVEY = gql`
  mutation createSurvey(
    $userId: ID!
    $brandIds: [ID!]!
    $question: String!
    $answers: [String!]!
    $image: String!
  ) {
    createSurvey(
      userId: $userId
      brandIds: $brandIds
      question: $question
      answers: $answers
      image: $image
    )
  }
`;

export default CREATE_SURVEY;
