import { gql } from "@apollo/client";

const GET_BRAND = gql`
  query getBrand($userId: ID!) {
    getBrand(userId: $userId) {
      id
      name
      email
      accountStatus
      facebook
      image
      instagram
      country
      city
      theme
      tiktok
      website
      youtube
      categories {
        id
        name
      }
      signature 
    }
  }
`;

export default GET_BRAND;
