import React, { ReactElement, FC, useState } from "react";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import GET_CAMPAIGNS from "../graphql/queries/getCampaigns";
import CHANGE_CAMPAIGN_STATUS from "../graphql/mutations/updateCampaignStatus";
import { useNavigate } from "react-router-dom";
import INITIALIZE_CHATROOMS from "../graphql/mutations/initializeChatrooms";
import EditCampaign from "../components/campaignComponents/editCampaign";
import { Select, MenuItem, Box } from "@mui/material";

interface campaign {
  id: String;
  name: String;
  createdAt: Date;
  user: any;
  campaignType: String;
  category: any;
  followerPreference: [String];
  media: String;
  status: String;
}

const CampaignListing: FC<any> = (): ReactElement => {
  const nav = useNavigate();
  const [campaignList, setCampaignList] = useState<[campaign] | []>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<String>("0");
  const [campaign, setCampaign] = useState<campaign>();
  const [sortOrder, setSortOrder] = useState<"newest" | "oldest">("newest");

  useQuery(GET_CAMPAIGNS, {
    onCompleted: (res) => {
      setCampaignList(res.adminGetCampaigns);
    },
  });

  const [initializeChatrooms] = useMutation(INITIALIZE_CHATROOMS);
  const [updateStatus] = useMutation(CHANGE_CAMPAIGN_STATUS, {
    refetchQueries: ["adminGetCampaigns"],
    onCompleted: () => {
      selectedCampaign !== "0" &&
        initializeChatrooms({
          variables: {
            campaignId: selectedCampaign,
          },
        });
    },
  });

  const handleAction = (action: String, id: String) => {
    action === "Approved" && setSelectedCampaign(id);
    updateStatus({
      variables: {
        campaignId: id,
        campaignStatus: action,
      },
    });
  };

  const [openEdit, setOpenEdit] = useState(false)

  const handleEdit = (campaign: campaign) => {
    setOpenEdit(true)
    setCampaign(campaign)
  };

  const showDetails = (id: String) => {
    nav(`/campaign/${id}`);
  };

  const sortCampaigns = (campaigns: campaign[]) => {
    return [...campaigns].sort((a, b) => {
      if (sortOrder === "newest") {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      } else {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      }
    });
  };

  return (
      <Grid
        item
        xs={12}
        sx={{
          width: "100vw",
          height: "calc(100vh - 64px)",
          backgroundColor: "whitesmoke",
          padding: 4,
        }}
      >
        <Box
            display="flex"
            justifyContent="flex-end"
        >
          <Select
              value={sortOrder}
              sx={{fontSize: '15px', marginBottom: '10px'}}
              onChange={(event) => setSortOrder(event.target.value as "newest" | "oldest")}
          >
            <MenuItem value={"newest"} sx={{fontSize: '15px'}}>Newest</MenuItem>
            <MenuItem value={"oldest"} sx={{fontSize: '15px'}}>Oldest</MenuItem>
          </Select>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: "100vw" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell align="center">Brand Name</TableCell>
                <TableCell align="center">Campaign Name</TableCell>
                <TableCell align="center">Category</TableCell>
                <TableCell align="center">No. of Followers</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Media</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Actions 1</TableCell>
                <TableCell align="center">Actions 2</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortCampaigns(campaignList).map((campaign, index) => (
                  <TableRow
                      key={`campaign-${index}-${campaign.id}`}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>

                    <TableCell align="center">{campaign?.user?.name}</TableCell>
                    <TableCell align="center" sx={{ maxWidth: 300 }}>
                      {campaign.name}
                    </TableCell>
                    <TableCell align="center">{campaign.category.name}</TableCell>
                    <TableCell align="center" sx={{ maxWidth: 300 }}>
                      {campaign.followerPreference.join(" ~ ")}
                    </TableCell>
                    <TableCell align="center">{campaign.campaignType}</TableCell>
                    <TableCell align="center">{campaign.media}</TableCell>
                    <TableCell align="center">{campaign.status}</TableCell>
                    <TableCell align="center">
                      {campaign.status === "Pending" && (
                          <Button
                              variant="contained"
                              color="success"
                              onClick={() => handleAction("Approved", campaign.id)}
                          >
                            Approve
                          </Button>
                      )}
                      {campaign.status === "Pending" && (
                          <Button
                              variant="contained"
                              color="error"
                              sx={{ marginLeft: 2 }}
                              onClick={() => handleAction("Rejected", campaign.id)}
                          >
                            {"Reject"}
                          </Button>
                      )}
                      {(campaign.status === "Approved" ||
                          campaign.status === "In_Progress") && (
                          <Button
                              variant="contained"
                              color="warning"
                              sx={{ marginLeft: 2 }}
                              onClick={() => handleAction("Cancelled", campaign.id)}
                          >
                            {"Cancel"}
                          </Button>
                      )}

                      <Button
                          variant="contained"
                          color="warning"
                          sx={{ marginLeft: 2 }}
                          onClick={() => handleEdit(campaign)}
                      >
                        {"Edit"}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                          variant="contained"
                          color="primary"
                          onClick={() => showDetails(campaign.id)}
                      >
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <EditCampaign openEdit={openEdit} setOpenEdit={setOpenEdit} campaign={campaign}/>
      </Grid>
  );
};

export default CampaignListing;
