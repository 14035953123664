import { gql } from "@apollo/client";

const GET_CAMPAIGN_RESULTS_BY_ID = gql`
  query getCampaignResults($campaignIds: [ID!]) {
    getCampaignResults(campaignIds: $campaignIds) {
      id
      userId
      campaignId
      instaImpressions
      instaLikes
      instaComments
      instaFollowers
      instaStoryViews
      instaReach
      tiktokLikes
      tiktokComments
      tiktokFollowers
      tiktokViews
      tiktokShares
      discountCodeUsed
      instaNonFollowers
      instaSaves
      instaShares
      instaProfileVisits
      instaAccountsReached
      instaAccountsEngaged
      instaLinkClicks
      tiktokAverageWatchTime
      tiktokWatchFullVideo
      tiktokPlays
      tiktokSaves
      tiktokShares
      updatedAt
    }
  }
`;

export default GET_CAMPAIGN_RESULTS_BY_ID;
