import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  TextField,
  CircularProgress
} from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import SignaturePad from 'react-signature-canvas';
import SAVED_SIGNATURE from '../../graphql/queries/getSavedSignature';
import SHOW_PAID_INTEREST from '../../graphql/mutations/interestedInPaidCampaign';
import GET_BRAND from '../../graphql/queries/getBrandInfo';
import UPDATE_USER_INFO from '../../graphql/mutations/updateUserInfo';
import GET_CONTRACT from '../../graphql/queries/getContract';
import GET_CAMPAIGN_DETAILS from '../../graphql/queries/getCampaignDetails';

interface CampaignContractProps {
  contractDetails: {
    id: string;
  influencer: { id: string; name: string; image: string };
  description: string;
  contractType: number;
  influencerQuote: number;
  brandQuote: number;
  finalFee: number;
  commissionModel: boolean;
  };
  onClose: () => void;
}

const CampaignContract: React.FC<CampaignContractProps> = ({
  contractDetails,
  onClose,
}) => {

    const [brandSignature, setBrandSignature] = useState<string | null>(null);
    const [influencerSignature, setInfluencerSignature] = useState<string | null>(null);
    const [adminSignature, setAdminSignature] = useState<string | null>(null);
    const [brandName, setBrandName] = useState<string>('');
    const [brandDate, setBrandDate] = useState<string>('');
    const [influencerName, setInfluencerName] = useState<string>('');
    const [influencerDate, setInfluencerDate] = useState<string>('');
    const [adminName, setAdminName] = useState<string>('');
    const [adminDate, setAdminDate] = useState<string>('');
    const [defaultContract, setDefaultContract] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [showSignaturePad, setShowSignaturePad] = useState(false);
    const [signatureData, setSignatureData] = useState('');
    const [updateUserInfo] = useMutation(UPDATE_USER_INFO);
    const [campaignContract, setCampaignContract] = useState<any>({});
    const [influencerContract, setInfluencerContract] = useState('');
    const [campaign, setCampaign] = useState<any>({});

   const [myQuote, setMyQuote] = useState<any>(0);
   const [currencyType, setCurrencyType] = useState('');
   const [myDescription, setDescription] = useState('');
   const [influencerCommissionModel, setInfluencerCommissionModel] = useState(false);
   const [openInterestModal, setOpenInterestModal] = useState(false);

   const { loading: savedSignatureLoading, data: savedSignatureData, refetch: refetchSavedSignature } = useQuery(SAVED_SIGNATURE, {
    variables: {  campaignId: localStorage.getItem('campaignId'),
                  userId: contractDetails.influencer.id },
    onCompleted: (data) => {
      if (data.getSavedSignature) {
        const { brand_signature, brand_name, brand_date, influencer_signature, influencer_name, influencer_date, admin_signature, admin_name, admin_date } = data.getSavedSignature;
        setBrandSignature(brand_signature);
        setBrandName(brand_name);
        setBrandDate(brand_date);
        setInfluencerSignature(influencer_signature);
        setInfluencerName(influencer_name);
        setInfluencerDate(influencer_date);
        setAdminSignature(admin_signature);
        setAdminName(admin_name);
        setAdminDate(admin_date);
      }
    },
    onError: (error) => {
      console.error('Error fetching saved signature data:', error);
    },
  });
  const sigPad = useRef<SignaturePad | null>(null);
  const { data: brandData } = useQuery(GET_BRAND, {
    variables: { userId: localStorage.getItem('currentUser') },
    skip: !contractDetails.influencer.id ,
  });
  const [showInterest, { loading: interestLoading }] = useMutation(SHOW_PAID_INTEREST, {
    onCompleted: () => {
      setMyQuote(0);
      setCurrencyType('');
      setDescription('');
      setOpenInterestModal(false);
      setSubmitting(false); 
    },
    refetchQueries: [
      'getCandidateCampaignsForInfluencer',
      'getInterestedCampaignsForInfluencer',
      'getInvitedCampaignsForInfluencer',
      'getCampaignsForInfluencer',
    ],
    onError: (error) => {
      console.error('Error showing interest:', error);
      setSubmitting(false);
    },
  });
  const [isNewSignature, setIsNewSignature] = useState(false);
   const handleAddSignature = (type: 'brand' | 'influencer' | 'admin') => {
    if (type === 'admin') {
      setIsNewSignature(true);
      setAdminSignature(brandData?.getBrand?.signature);
    }
  };
  async function loadInfluencerContract(contractLink: RequestInfo | URL) {
    try {
      const response = await fetch(contractLink);
      const data = await response.text();
      setInfluencerContract(data);
    } catch (error) {
      console.error("Error loading contract:", error);
    }
  }

   useQuery(GET_CONTRACT, {
    variables: {
      campaignId: localStorage.getItem('campaignId'),
      userId: contractDetails.influencer.id,
    },
    onCompleted: (res) => {
      setInfluencerContract(res.getContract);
      loadInfluencerContract(res.getContract);
    },
    onError: (error) => {
      console.error("Error fetching contract data:", error);
    },
  });
  const { data } = useQuery(GET_CAMPAIGN_DETAILS, {
    fetchPolicy: "network-only",
    variables: {
      campaignId: localStorage.getItem('campaignId'),
    },
    onCompleted: (res) => {
      setCampaign(res.getCampaignDetails);
    },
  });
  const handleRemoveSignature = (type: 'brand' | 'influencer' | 'admin') => {
    if (type === 'brand') {
      setBrandSignature(null);
    } else if (type === 'influencer') {
      setInfluencerSignature(null);
    } else if (type === 'admin') {
      setAdminSignature(null);
    }
  };
  const loadCampaignContract = async () => {
    if (campaign?.contract) {
      await fetch(campaign?.contract)
        .then((r) => {
          r.text().then((d) => {
            return setCampaignContract(d);
          });
        })
        .catch((error) => {
          console.error("Error fetching campaign contract:", error);
        });
    } else {
      console.error("Campaign contract is null or undefined");
    }
  };
 
  useEffect(() => {
    if (campaign) {
      loadContract();
      loadCampaignContract();
    }
  }, [campaign]);

  const loadContract = async () => {
    try {
      const response = await fetch(
        "https://authentic.lon1.cdn.digitaloceanspaces.com/authentic_default_contract.txt"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch default contract");
      }
      const data = await response.text();
      setDefaultContract(data);
    } catch (error) {
      console.error("Error fetching default contract:", error);
    }
  };

const handleSubmitSignatures = async () => {
    setSubmitting(true); 
    try {
      await showInterest({
        variables: {
          userId: contractDetails.influencer.id,
          campaignId: localStorage.getItem('campaignId') ?? '',
          influencerQuote: parseInt(myQuote),
          influencerQuoteType: currencyType,
          description: myDescription,
          commissionModel: influencerCommissionModel,
          brandSignature,
          brandName,
          brandDate,
          influencerSignature,
          influencerName,
          influencerDate,
          adminSignature,
          adminName,
          adminDate,
        },
      });
        onClose();
    } catch (error) {
      console.error('Error showing interest:', error);
    } finally {
      setSubmitting(false); 
    }
  };
  
  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="contract-and-signatures-modal"
      aria-describedby="contract-and-signatures-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: 800,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxHeight: '80vh',
          overflowY: 'auto',
        }}
      >

<>
          <div
            dangerouslySetInnerHTML={
              { __html: defaultContract } || { __html: "Loading..." }
            }
          />
          {campaignContract && (
            <div>
              <b>
                <br />
                Additional Clauses by brand
              </b>
              <div
                dangerouslySetInnerHTML={
                  { __html: campaignContract } || { __html: "Loading..." }
                }
              />
              <b>
                <br />
                Additional Clauses by Influencer
              </b>
              <div
                dangerouslySetInnerHTML={
                  { __html: influencerContract } || { __html: "Loading..." }
                }
              />
            </div>
          )}
        </>

        <Grid item xs={12} container justifyContent="center" mt={2}>
          <Box sx={{ width: '30%', textAlign: 'center' }}>
            <Typography variant="subtitle2">Brand Signature</Typography>
            <Box mt={1}>
              {brandSignature ? (
                <>
                  <img
                    src={brandSignature}
                    alt="Brand Signature"
                    style={{ maxWidth: 150, maxHeight: 150, marginBottom: 10 }}
                  />
                </>
              ) : (
                <label>No signature</label>

              )}
            </Box>
            {brandSignature && (
              <>
                <TextField
                  id="brand-name"
                  label="Name"
                  variant="standard"
                  value={brandName}
                  style={{ display: 'block', marginTop: 10 }}
                />
                <TextField
                  id="brand-date"
                  label="Date"
                  type="date"
                  variant="standard"
                  value={brandDate}
                  style={{ display: 'block', marginTop: 10 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
          </Box>

          <Box sx={{ width: '30%', textAlign: 'center' }}>
            <Typography variant="subtitle2">Influencer Signature</Typography>
            <Box mt={1}>
              {influencerSignature ? (
                <>
                  <img
                    src={influencerSignature}
                    alt="Influencer Signature"
                    style={{ maxWidth: 150, maxHeight: 150, marginBottom: 10 }}
                  />
                </>
              ) : (
                <label>No signature</label>
              )}
            </Box>
            {influencerSignature && (
              <>
                <TextField
                  id="influencer-name"
                  label="Name"
                  variant="standard"
                  value={influencerName}
                  style={{ display: 'block', marginTop: 10 }}
                />
                <TextField
                  id="influencer-date"
                  label="Date"
                  type="date"
                  variant="standard"
                  value={influencerDate}
                  style={{ display: 'block', marginTop: 10 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
          </Box>

          <Box sx={{ width: '30%', textAlign: 'center' }}>
            <Typography variant="subtitle2">Admin Signature</Typography>
            <Box mt={1}>
            {adminSignature ? (
          <>
            <img
              src={adminSignature}
              alt="Admin Signature"
              style={{ maxWidth: 150, maxHeight: 150, marginBottom: 10 }}
            />
            {isNewSignature && (
              <Button onClick={() => handleRemoveSignature('admin')}>Remove</Button>
            )}
          </>
        ) : (
          <Button variant="contained" color="primary" onClick={() => handleAddSignature('admin')}>
            Add Signature
          </Button>
        )}
            </Box>
            {adminSignature && (
              <>
                <TextField
                  id="admin-name"
                  label="Name"
                  variant="standard"
                  value={adminName}
                  onChange={(e) => {isNewSignature &&  (setAdminName(e.target.value))}}
                  style={{ display: 'block', marginTop: 10 }}
                />
                <TextField
                  id="admin-date"
                  label="Date"
                  type="date"
                  variant="standard"
                  value={adminDate}
                  onChange={(e) => {isNewSignature &&  (setAdminDate(e.target.value))}}
                  style={{ display: 'block', marginTop: 10 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
          </Box>
         <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitSignatures}
            disabled={submitting}
            style={{ minWidth: 200 }}
          >
            {submitting ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </div>
        </Grid>

        <Button onClick={onClose} variant="contained" color="primary" sx={{ mt: 2 }}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default CampaignContract;
