import { gql } from "@apollo/client";

const CHANGE_CAMPAIGN = gql`
  mutation adminUpdateCampaign(
    $campaignId: ID!
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
  ) {
    adminUpdateCampaign(
      campaignId: $campaignId
      endDate: $endDate
      startDate: $startDate
    )
  }
`;

export default CHANGE_CAMPAIGN;
