import { ReactElement, useEffect, useState } from "react";
import {
  Box,
  Link,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Button,
  Badge,
  Popover,
  Divider,
  List,
  ListItem,
  Avatar,
  Grid,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { routes } from "../routes";
import { NavLink, useNavigate } from "react-router-dom";
import theme from "../globalTheme";
import { useMutation, useQuery } from "@apollo/client";
import FETCH_NOTIFICATIONS from "../graphql/queries/fetchNotifications";
import MARK_AS_READ from "../graphql/mutations/markAsRead";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Link as RouterLink } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoneAllIcon from "@mui/icons-material/DoneAll";


const NotificationsPopover = () => {
  const { data: notifications, startPolling } = useQuery(FETCH_NOTIFICATIONS, {
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
    pollInterval: 5000,
  });

  const [markAsRead] = useMutation(MARK_AS_READ, {
    refetchQueries: ["adminGetNotifications"],
  });

  const totalUnRead = notifications?.adminGetNotifications.length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    startPolling(5000);
  }, [notifications, startPolling]);

  return (
    <>
      <IconButton
        color={open ? "info" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
            height: 400,
            overflowY: "scroll",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <List disablePadding>
          {notifications?.adminGetNotifications.map((notification: any) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              markAsRead={markAsRead}
            />
          ))}
        </List>
      </Popover>
    </>
  );
};

function NotificationItem({ notification, markAsRead }: any) {
  const { avatar, title } = renderContent(notification);

  return (
    <Link to={notification.url} component={RouterLink}>
      <ListItem
        sx={{
          py: 1,
          px: 2.5,
          mt: "1px",
        }}
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={title}
          secondary={
            <Grid item xs container justifyContent="space-between">
              <Typography
                variant="caption"
                sx={{
                  mt: 0.5,
                  display: "flex",
                  alignItems: "center",
                  color: "text.disabled",
                }}
              >
                <AccessTimeIcon sx={{ mr: 0.5, width: 16, height: 16 }} />
                {new Date(notification.createdAt).toLocaleDateString()}
              </Typography>
              <Button
                variant="text"
                sx={{
                  mt: 0.5,
                  display: "flex",
                  alignItems: "center",
                  color: "primary",
                  fontSize: "0.5rem",
                }}
                size="small"
                onClick={() => {
                  markAsRead({ variables: { id: notification.id } });
                }}
              >
                Mark As Read &nbsp;{" "}
                <DoneAllIcon sx={{ width: 13, height: 13 }} />
              </Button>
            </Grid>
          }
        />
      </ListItem>
    </Link>
  );
}

function renderContent(notification: any) {
  const title = (
    <Typography variant="subtitle2">
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        {notification.text}
      </Typography>
    </Typography>
  );

  if (notification.type === "order_placed") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/ic_notification_package.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "order_shipped") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "mail") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/ic_notification_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "chat_message") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/ic_notification_chat.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: notification.avatar ? (
      <img alt={notification.title} src={notification.avatar} />
    ) : null,
    title,
  };
}

const Navbar = (props: any): ReactElement => {
  const { setLoggedOut } = props;
  const nav = useNavigate();

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleLogout: any = () => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("client");
    localStorage.removeItem("uid");
    setLoggedOut();
    nav("/admin-portal");
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: "primary.main",
      }}
    >
      <Container sx={{maxWidth: '100% !important'}}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {routes.map((page) => (
                <Link
                  key={page.key}
                  component={NavLink}
                  to={page.path}
                  color="black"
                  underline="none"
                  variant="button"
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page.title}</Typography>
                  </MenuItem>
                </Link>
              ))}
              <MenuItem onClick={() => handleLogout()}>
                <Typography textAlign="center">LOGOUT</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            Authentic Admin Dashboard
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: "1rem",
              }}
            >
              {routes.map((page) => (
                <Link
                  key={page.key}
                  component={NavLink}
                  to={page.path}
                  color="black"
                  underline="none"
                  variant="button"
                  sx={{
                    fontSize: "large",
                    marginLeft: "2rem",
                    py: 2,
                    px: 0.5,
                    borderRadius: 1,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                      color: "#FFF",
                    },
                  }}
                >
                  {page.title}
                </Link>
              ))}
              <Button
                variant="text"
                sx={{
                  color: "#000",
                  fontSize: "large",
                  marginLeft: "2rem",
                  py: 2,
                  px: 0.5,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    color: "#FFF",
                  },
                }}
                onClick={() => handleLogout()}
              >
                LOG OUT
              </Button>
            </Box>
          </Box>
          <Box>
            <NotificationsPopover />
          </Box>
        </Toolbar>
      </Container>
    </Box>
  );
};

export default Navbar;
