import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import CREATE_CATEGORY from "../../graphql/mutations/createCategory";
import EDIT_CATEGORY from "../../graphql/mutations/editCategory";
import GET_CATEGORIES from "../../graphql/queries/getCategories";

const Categories = () => {
  const [category, setCategory] = useState("");

  const { data } = useQuery(GET_CATEGORIES);
  const [create] = useMutation(CREATE_CATEGORY, {
    variables: {
      name: category,
    },
    onCompleted: () => {
      setCategory("");
    },
    refetchQueries: ["adminGetCategories"],
  });

  const [edit] = useMutation(EDIT_CATEGORY, {
    refetchQueries: ["adminGetCategories"],
  });

  const editCategory = (categoryId: string, change: string) => {
    edit({
      variables: {
        change: change,
        categoryId: categoryId,
      },
    });
  };

  return (
    <Grid
      item
      xs={12}
      container
      sx={{ maxHeight: "800px" }}
      justifyContent="flex-start"
      alignItems="start"
    >
      <Grid item xs={12} p={2} sx={{ height: 80 }}>
        <TextField
          id="category-title"
          label="Enter New Category Name"
          variant="standard"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          sx={{ width: 300 }}
        />
        <Button
          variant="contained"
          disabled={category === ""}
          onClick={() => create()}
          sx={{ height: 45, marginLeft: 10 }}
        >
          Create
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ maxHeight: 720, overflow: "scroll" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: "95%" }} aria-label="survey listing table">
            <TableHead>
              <TableRow>
                <TableCell width="20">#</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Delete</TableCell>
                <TableCell align="center">Disable</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.adminGetCategories.map((category: any, index: number) => (
                <TableRow
                  key={`${category.id}-${index}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index}
                  </TableCell>
                  <TableCell align="center">{category.name}</TableCell>
                  <TableCell align="center">
                    {!category.status && (
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => editCategory(category.id, "enable")}
                      >
                        Enable
                      </Button>
                    )}
                    {category.status && (
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => editCategory(category.id, "disable")}
                      >
                        Disable
                      </Button>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => editCategory(category.id, "delete")}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Categories;
