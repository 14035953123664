import * as React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const StatCard = ({ title, value }: any) => {
  return (
    <Card
      sx={{
        width: 345,
        height: 150,
        marginTop: 2,
        marginRight: 2,
        borderTop: 2,
      }}
    >
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" align="center">
          <b>{title}</b>
        </Typography>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          color="primary.dark"
          align="center"
        >
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StatCard;
