import {
	Button,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import React, {useState} from "react";
import S3 from "aws-s3";
import s3_config from "../../config/s3_config.json";
import {useMutation, useQuery} from "@apollo/client";
import CREATE_SURVEY from "../../graphql/mutations/createSurvey";
import GET_SURVEY_LIST from "../../graphql/queries/getSurveys";
import GET_BRANDS from "../../graphql/queries/getBrands";
import MultiSelectDropdown from "../SharedComponents/multiSelectDropdown";
import InfluencerListModal from "./influencerList";
import DISABLE_SURVEY from "../../graphql/mutations/disableSurvey";
import ENABLE_SURVEY from "../../graphql/mutations/enableSurvey";
import DELETE_SURVEY from "../../graphql/mutations/deleteSurvey";

window.Buffer = window.Buffer || require("buffer").Buffer;

const S3Client = new S3(s3_config);

type ResponseType = {
	answer: string;
	__typename: string;
};
type SelectedAnswerType = {
	surveyId: number | string;
	answer: string;
};
const Survey = () => {
	const [preview, setPreview] = useState<any>(null);
	const [showInfluencerList, setShowInfluencerList] = useState<boolean>(false);
	const [selectedAnswer, setSelectedAnswer] = useState<SelectedAnswerType>({
		surveyId: 0,
		answer: "",
	});
	const [selectedBrands, setSelectedBrands] = useState<any>([]);
	const [survey, setSurvey] = useState({
		question: "",
		answer1: "",
		answer2: "",
		answer3: "",
		answer4: "",
	});

	const {data: brands} = useQuery(GET_BRANDS);

	const [createSurvey] = useMutation(CREATE_SURVEY, {
		refetchQueries: ["adminGetSurveys"],
		onCompleted: () => {
			setSurvey({
				question: "",
				answer1: "",
				answer2: "",
				answer3: "",
				answer4: "",
			});
			setPreview(null);
			setSelectedBrands([]);
		},
	});
	const {data} = useQuery(GET_SURVEY_LIST);

	const handleCapture = ({target}: any) => {
		S3Client.uploadFile(target.files[0])
			.then((data: any) => {
				setPreview(data.location);
			})
			.catch((err: any) => {
			});
	};

	const create = () => {
		createSurvey({
			variables: {
				userId: localStorage.getItem("currentUser"),
				brandIds: selectedBrands,
				question: survey.question,
				answers: [
					survey.answer1,
					survey.answer2,
					survey.answer3,
					survey.answer4,
				],
				image: preview,
			},
		});
	};

	function getAnswerCount(answer: string, responses: [ResponseType]) {
		var count = 0;

		responses.forEach((response) => response.answer === answer && count++);

		return count;
	}

	function handleAnswerClick(surveyId: string | number, answer: string) {
		setSelectedAnswer({
			answer: answer,
			surveyId: surveyId,
		});
		setShowInfluencerList(true);
	}

	const [disableSurvey] = useMutation(DISABLE_SURVEY, {
		refetchQueries: ["adminGetSurveys"]
	});

	const [enableSurvey] = useMutation(ENABLE_SURVEY, {
		refetchQueries: ["adminGetSurveys"]
	});

	const [deleteSurvey] = useMutation(DELETE_SURVEY, {
		refetchQueries: ["adminGetSurveys"]
	});


	return (
		<Grid item xs={12} container>
			<Grid
				item
				xs={3}
				p={2}
				component={Paper}
				sx={{overflow: "scroll", height: "800px"}}
				container
				justifyContent="center"
			>
				{!preview && (
					<Button
						variant="contained"
						component="label"
						fullWidth
						sx={{height: 40}}
					>
						Add Image
						<input
							type="file"
							accept="image/*"
							onChange={handleCapture}
							hidden
						/>
					</Button>
				)}
				{preview && (
					<img
						src={preview}
						alt="survey-cover"
						width="300"
						style={{marginBottom: 25}}
					/>
				)}
				<TextField
					id="survey-question"
					label="Enter Survey Question Here"
					variant="standard"
					fullWidth
					multiline
					value={survey.question}
					onChange={(e) => setSurvey({...survey, question: e.target.value})}
					sx={{marginBottom: 5}}
				/>
				<TextField
					id="survey-answer-1"
					label="Survey Option #1"
					variant="standard"
					fullWidth
					required
					value={survey.answer1}
					onChange={(e) => setSurvey({...survey, answer1: e.target.value})}
					sx={{marginBottom: 3}}
				/>
				<TextField
					id="survey-answer-2"
					label="Survey Option #2"
					variant="standard"
					fullWidth
					required
					value={survey.answer2}
					onChange={(e) => setSurvey({...survey, answer2: e.target.value})}
					sx={{marginBottom: 3}}
				/>
				<TextField
					id="survey-answer-3"
					label="Survey Option #3 (optional)"
					variant="standard"
					fullWidth
					value={survey.answer3}
					onChange={(e) => setSurvey({...survey, answer3: e.target.value})}
					sx={{marginBottom: 3}}
				/>
				<TextField
					id="survey-answer-4"
					label="Survey Option #4 (optional)"
					variant="standard"
					fullWidth
					value={survey.answer4}
					disabled={survey.answer3 === ""}
					onChange={(e) => setSurvey({...survey, answer4: e.target.value})}
					sx={{marginBottom: 3}}
				/>
				{brands && (
					<MultiSelectDropdown
						selectFor="Brands"
						list={brands?.adminGetBrands}
						setSelectedIds={setSelectedBrands}
					/>
				)}
				<Button
					fullWidth
					variant="contained"
					disabled={
						survey.question === "" ||
						survey.answer1 === "" ||
						survey.answer2 === "" ||
						preview === null
					}
					sx={{marginTop: 5}}
					onClick={() => create()}
				>
					Create
				</Button>
			</Grid>
			<Grid item xs={9}>
				<TableContainer component={Paper}>
					<Table sx={{minWidth: "60%"}} aria-label="survey listing table">
						<TableHead>
							<TableRow>
								<TableCell width="20">#</TableCell>
								<TableCell align="center">Brand Name</TableCell>
								<TableCell align="center">Survey Question</TableCell>
								<TableCell align="center">Answer 1</TableCell>
								<TableCell align="center">Answer 2</TableCell>
								<TableCell align="center">Answer 3</TableCell>
								<TableCell align="center">Answer 4</TableCell>
								<TableCell align="center">Enable/Disable</TableCell>
								<TableCell align="center">Delete Survey</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data?.adminGetSurveys.map((survey: any, index: number) => (
								<TableRow
									key={`${survey.id}-${index}`}
									sx={{
										"&:last-child td, &:last-child th": {border: 0},
										backgroundColor: index % 2 === 0 ? "" : "whitesmoke",
									}}
								>
									<TableCell component="th" scope="row">
										{index}
									</TableCell>
									<TableCell align="center">
										{survey.brandName}
									</TableCell>
									<TableCell align="center">{survey.question}</TableCell>
									<TableCell
										align="center"
										onClick={() =>
											handleAnswerClick(survey.id, survey.answers[0])
										}
										sx={{cursor: "pointer"}}
									>
										{survey.answers[0]}
										<br/>
										{`( ${getAnswerCount(
											survey.answers[0],
											survey.surveyAnswers
										)}
                    )`}
									</TableCell>
									<TableCell
										align="center"
										onClick={() =>
											handleAnswerClick(survey.id, survey.answers[1])
										}
										sx={{cursor: "pointer"}}
									>
										{survey.answers[1]}
										<br/>
										{`( ${getAnswerCount(
											survey.answers[1],
											survey.surveyAnswers
										)}
                    )`}
									</TableCell>
									<TableCell
										align="center"
										onClick={() =>
											handleAnswerClick(survey.id, survey.answers[2])
										}
										sx={{cursor: "pointer"}}
									>
										{survey.answers[2]}
										<br/>
										{`( ${getAnswerCount(
											survey.answers[2],
											survey.surveyAnswers
										)}
                  )`}
									</TableCell>
									<TableCell
										align="center"
										onClick={() =>
											handleAnswerClick(survey.id, survey.answers[3])
										}
										sx={{cursor: "pointer"}}
									>
										{survey.answers[3]}
										<br/>
										{`( ${getAnswerCount(
											survey.answers[3],
											survey.surveyAnswers
										)}
                  )`}
									</TableCell>
									<TableCell
										align="center"
										sx={{cursor: "pointer"}}
									>
										<Button
											variant="contained"
											color="warning"
											onClick={() =>

												survey.isEnabled ? disableSurvey({
													variables: {
														surveyId: survey.id,
													},
												}) : enableSurvey({
													variables: {
														surveyId: survey.id,
													},
												})
											}
										>
											{survey.isEnabled ? 'Disable' : 'Enable'}
										</Button>
									</TableCell>

									<TableCell
										align="center"
										sx={{cursor: "pointer"}}
									>
										<Button
											variant="contained"
											color="warning"
											onClick={() => deleteSurvey({
												variables: {
													surveyId: survey.id,
												},
											})}
										>
											Delete
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			{showInfluencerList && (
				<InfluencerListModal
					open={showInfluencerList}
					setOpen={setShowInfluencerList}
					surveyId={selectedAnswer.surveyId}
					answer={selectedAnswer.answer}
				/>
			)}
		</Grid>
	);
};

export default Survey;
